import React from 'react'
import styled, { css } from 'styled-components'

import FICColors from '../../styles/FICColors'
import FICStyles from '../../styles/FICStyles'
import { FICAutocompleteImpl } from './FICAutocomplete'

export const FICAutocomplete = styled(props => (
  <FICAutocompleteImpl {...props} />
))`
  input {
    background-color: ${FICColors.white};
    border: 1px solid ${FICColors.borderColor};
    border-radius: 4px;
    width: 100%;
    color: ${FICColors.black};
    height: 30px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    padding: 4px 8px;
    box-sizing: border-box;
    outline: none;
    ${props =>
      props.size === 'large' &&
      css`
        height: 40px;
        font-size: 16px;
      `}
    ${props =>
      props.isValid === false &&
      css`
        border-color: ${FICColors.red};
        box-shadow: 0 0 2px ${FICColors.red};
      `}
    ${props =>
      props.isDisabled &&
      css`
        background-color: ${FICColors.inputDisabled};
        cursor: not-allowed;
      `}
    &:focus {
      border-color: ${FICColors.blue};
      box-shadow: 0 0 0 2px ${FICColors.blue}3D, 0 0 0 0px ${FICColors.blue},
        inset 0 1px 2px 0 rgba(51, 51, 51, 0.12);
      ${props =>
        props.isValid === false &&
        css`
          border-color: ${FICColors.red};
          box-shadow: 0 0 2px ${FICColors.red};
        `}
    }
    &::placeholder {
      color: ${FICColors.placeholder};
      font-size: 14px;
    }
  }
`

export const MenuOuter = styled.div`
  border: ${FICStyles.border};
  background-color: ${FICColors.white};
  border-top-color: #e6e6e6;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: 0;
  max-height: 200px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: visible;
`

export const AutocompleteMenu = styled.div`
  box-sizing: border-box;
`

export const AutocompleteOption = styled.div`
  padding: 5px 10px;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  ${props =>
    props.focus &&
    css`
      background-color: ${FICColors.deepBlue};
      color: ${FICColors.white};
    `}
  &:active {
    background-color: rgba(0, 124, 228, 0.1);
  }
`
