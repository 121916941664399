import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FICPaymentAccount } from '../../../common/types'

interface PaymentAccountsState {
  paymentAccounts: FICPaymentAccount[]
}

const initialState: PaymentAccountsState = {
  paymentAccounts: [],
}

const paymentAccountsSlice = createSlice({
  name: 'paymentAccounts',
  initialState: initialState,
  reducers: {
    setPaymentAccounts: (state, action: PayloadAction<FICPaymentAccount[]>) => {
      state.paymentAccounts = action.payload
    },
    setFavouritePaymentAccount: (
      state,
      action: PayloadAction<{ id: number }>
    ) => {
      state.paymentAccounts.forEach(account => {
        if (account.id === action.payload.id) {
          account.favorite = !account.favorite
        } else {
          account.favorite = false
        }
      })
    },
    deletePaymentAccount: (state, action: PayloadAction<{ id: number }>) => {
      state.paymentAccounts = state.paymentAccounts.filter(
        account => account.id !== action.payload.id
      )
    },
  },
})

const paymentAccounts = paymentAccountsSlice.reducer

export const {
  deletePaymentAccount,
  setFavouritePaymentAccount,
  setPaymentAccounts,
} = paymentAccountsSlice.actions

export default paymentAccounts
