import FICDateTime from '../../../common/dateTime'
import { GlobalStore } from '../../app/rootReducer'
import PreferencesStore, {
  DismissConf,
  PreferencesWithDateVisibility,
} from './preferencesStoreTypes'

export const getPreferences = (state: GlobalStore): PreferencesStore =>
  state.preferences

export const getBookmarkKeys = (state: GlobalStore): string[] =>
  getPreferences(state).sidebarBookmarkKeys

export const getShowBannerDuplicatedProductCode = (
  state: GlobalStore
): boolean => {
  return (
    getDaysFromPreferenceValue('showBannerDuplicatedProductCode', state) >= 0
  )
}

export const getDaysFromPreferenceValue = (
  key: keyof PreferencesWithDateVisibility,
  state: GlobalStore
): number => {
  const valueRedux = getPreferences(state)[key]
  if (!valueRedux) return -1

  return FICDateTime.diff('now', valueRedux, 'days')
}

export const getAccountantDashbordTourConf = (
  state: GlobalStore
): DismissConf => state.preferences.accountantDashboardTourConf

export const getShowAccountantDashboardTour = (state: GlobalStore): boolean => {
  const { dismissCount, showAt } = state.preferences.accountantDashboardTourConf
  if (state.support.isSupport) return false
  if (!showAt) return dismissCount < 5

  return (
    FICDateTime.isAfter('now', showAt, 'days') &&
    dismissCount < 5 &&
    !state.support.isSupport
  )
}

export const getHiddenModules = (state: GlobalStore): string[] =>
  state.preferences.hiddenModules

export const getIsTsPayAnonEnabled = (state: GlobalStore): boolean =>
  state.preferences.tsPayAnonEnabled

export const getShowTd29 = (state: GlobalStore): boolean => {
  return state.preferences.showTd29
}
