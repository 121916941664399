import { ValidationResult, ValidatorParams } from './types'
import { Validation } from './validatorResultMaker'

const PIVA_REGEX = /^[0-9]{11}$/

/**
 * Vat number validator
 * @param {string} vatNumber - The vat number to validate
 * @param {ValidatorParams} [params] - The validation parameters
 * @return {ValidationResult}
 * */
export const validateVatNumber = (
  vatNumber: string,
  params?: ValidatorParams
): ValidationResult => {
  if (vatNumber === '') {
    if (params?.allowEmpty) return Validation.success()

    return Validation.error('La partita IVA è obbligatoria')
  }

  if (params?.allowOnlyNumber && vatNumber.startsWith('IT')) {
    return Validation.error(
      'La partita IVA non può iniziare con "IT", inserire solo le cifre senze prefisso.'
    )
  }

  if (vatNumber.startsWith('IT')) {
    vatNumber = vatNumber.substring(2)
  }

  if (!PIVA_REGEX.test(vatNumber)) {
    return Validation.error('La partita IVA deve contenere 11 cifre')
  }

  const digits = vatNumber.split('').map(c => parseInt(c))
  const lastDigit = digits.pop()
  const check = digits.reduce((acc, c, i) => {
    if (i % 2 !== 0) {
      c = 2 * c
      if (c > 9) {
        c = c - 9
      }
    }
    acc += c
    return acc
  }, 0)

  if ((10 - (check % 10)) % 10 !== lastDigit) {
    return Validation.error('Partita IVA non valida')
  }
  return Validation.success()
}
