import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PresentationModalService } from '../../../components/layout/components/basePage/components/modals/components/servicePresentationModal/types'
import sendEmailConfirmation from '../auth/person/thunks/sendEmailConfirmation'
import initialState from './modalsInitialState'
import {
  BooleanModals,
  ShowActivateCompanyRegistryModal,
} from './modalsStoreTypes'

type ShowHideModal = {
  name: BooleanModals
}

type ToggleModal = ShowHideModal & {
  visible?: boolean
}

type SetTSPayService = {
  service: PresentationModalService
}

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<ShowHideModal>) => {
      const { name } = action.payload
      state[name] = true
    },
    closeModal: (state, action: PayloadAction<ShowHideModal>) => {
      const { name } = action.payload
      state[name] = false
    },
    toggleModal: (state, action: PayloadAction<ToggleModal>) => {
      const { name, visible } = action.payload
      state[name] = visible ?? !state[name]
    },
    openServicePresentationModal: (
      state,
      action: PayloadAction<SetTSPayService>
    ) => {
      state.showServicePresentationModal = action.payload.service
    },
    closeServicePresentationModal: state => {
      state.showServicePresentationModal = false
    },
    toggleActivateCompanyRegistryModal: (
      state,
      action: PayloadAction<ShowActivateCompanyRegistryModal>
    ) => {
      state.showActivateCompanyRegistryModal = {
        show: action.payload.show,
        title: action.payload.title,
        subtitle: action.payload.subtitle,
        plan: action.payload.plan,
        accessing1PModule: action.payload.accessing1PModule,
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(sendEmailConfirmation.fulfilled, state => {
      state.showEmailConfirmSentModal = true
    })
  },
})

const modals = modalsSlice.reducer

export const {
  closeModal,
  closeServicePresentationModal,
  openModal,
  openServicePresentationModal,
  toggleActivateCompanyRegistryModal,
  toggleModal,
} = modalsSlice.actions
export default modals
