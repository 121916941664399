import { Icon } from '@fattureincloud/fic-design-system'
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'

import FICColors from '../../../common/styles/FICColors'
import FICBaseButton from './FICBaseButton'

/**
 * Renderizza un bottone.
 * Può avere sia href che onClick
 * Supporta isLoading con spinner
 * Può avere due icone (destra, sinistra) o una sola centrale ma senza testo, con larghezza minima fissa
 * Può avere solo un'icona, senza testo e di larghezza auto (un quadrato)
 *
 * @param props
 * @returns {*}
 * @constructor
 */
function FICButtonImpl(props) {
  return <FICBaseButton {...props} renderButton={renderButton} />
}

function renderButton({ icon, iconLeft, iconRight, isLoading, text }) {
  let iconElement = null
  let iconLeftElement = null
  let iconRightElement = null

  if (isLoading) {
    iconElement = <LeftIcon icon={faCircleNotch} spin />
  } else if (iconLeft) {
    iconLeftElement = <LeftIcon icon={iconLeft} text={text} />
  } else if (iconRight) {
    iconRightElement = <RightIcon icon={iconRight} text={text} />
  } else if (icon) {
    iconElement = <Icon icon={icon} />
  }

  return (
    <Fragment>
      {iconLeftElement}
      {iconElement}
      {!isLoading && text}
      {iconRightElement}
    </Fragment>
  )
}

const LeftIcon = styled(Icon)`
  margin-right: ${({ text }) => (text ? 4 : 0)}px;
`

const RightIcon = styled(Icon)`
  margin-left: ${({ text }) => (text ? 4 : 0)}px;
`

function getColorRoot(secondary) {
  return secondary ? FICColors.button.secondary : FICColors.button.primary
}

const defaultColor = FICColors.button.primary.blue

const FICButton = styled(FICButtonImpl)`
  display: inline-block;
  background-color: ${props =>
    props.color
      ? getColorRoot(props.useSecondaryColor)[props.color].normal
      : defaultColor.normal};
  color: ${props =>
    props.color
      ? getColorRoot(props.useSecondaryColor)[props.color].text
      : defaultColor.text};
  border: 1px solid rgba(51, 51, 51, 0.16);
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(51, 51, 51, 0.08);
  min-width: 105px;
  width: auto;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  ${props => css`
    padding: ${props.customPadding || '4px 30px'};
  `}
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none !important;
  ${props =>
    !props.isLoading &&
    css`
      ${props =>
        !props.isDisabled &&
        css`
          &:hover {
            background-color: ${props =>
              props.color
                ? getColorRoot(props.useSecondaryColor)[props.color].hover
                : defaultColor.hover};
            color: ${props =>
              props.color
                ? getColorRoot(props.useSecondaryColor)[props.color].textHover
                : FICColors.white};
          }
        `}
      &:active {
        background-color: ${props =>
          props.color
            ? getColorRoot(props.useSecondaryColor)[props.color].active
            : defaultColor.active};
      }
      &:focus {
        text-decoration: none;
      }
    `}

  .svg-inline--fa {
    font-size: 0.8em;
    color: ${props =>
      props.color
        ? getColorRoot(props.useSecondaryColor)[props.color].text
        : defaultColor.text};
  }
  ${props =>
    props.size === 'small' &&
    css`
      min-width: 91px;
    `}
  ${props =>
    props.size === 'large' &&
    css`
      min-width: 111px;
      height: 40px;
      line-height: 30px;
    `}
  ${props =>
    props.isDisabled &&
    css`
      background-color: #fafafa !important;
      border-color: #ebedee !important;
      color: #c4cbcf !important;
      cursor: not-allowed;
      .svg-inline--fa {
        color: #c4cbcf !important;
      }
    `}
  ${props =>
    props.text === undefined &&
    css`
      min-width: unset;
      line-height: unset;
      height: auto;
    `}
  ${props =>
    props.isLoading &&
    css`
      cursor: not-allowed;
    `}
`

FICButtonImpl.propTypes = FICButton.propTypes = {
  color: PropTypes.string,
  /* colore del bottone */
  useSecondaryColor: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  reactRouterLink: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  iconLeft: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  iconRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.object,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  customPadding: PropTypes.string,
}

FICButtonImpl.defaultProps =
  FICButton.defaultProps =
  renderButton.defaultProps =
    {
      ...FICBaseButton.defaultProps,
      ...{
        color: undefined,
        useSecondaryColor: false,
        size: 'medium',
        icon: undefined,
        iconLeft: undefined,
        iconRight: undefined,
        text: undefined,
        reactRouterLink: false,
        style: undefined,
        isLoading: undefined,
        onClick: undefined,
      },
    }

renderButton.propTypes = {
  text: PropTypes.string,
  isLoading: PropTypes.bool,
  iconLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iconRight: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

renderButton.defaultProps = {
  text: undefined,
  isLoading: false,
  iconLeft: undefined,
  iconRight: undefined,
  icon: undefined,
}

export default FICButton
