import React from 'react'
import { useSelector } from 'react-redux'
import { css } from 'styled-components'
import styled from 'styled-components'

import { getLoaders } from '../../redux/features/loaders/loadersSelectors'
import LoadingCloud from './LoadingCloud'

interface Props {
  isInner?: boolean
  children?: JSX.Element
  forceLoading?: boolean
}

const LoadingOverlay = ({
  children,
  forceLoading,
  isInner,
}: Props): JSX.Element => {
  const { inner, outer } = useSelector(getLoaders)
  const isLoading = forceLoading || (isInner ? inner : outer)

  return !isLoading && children ? (
    children
  ) : (
    <Wrapper hasChildren={!!children} isLoading={isLoading} isInner={!!isInner}>
      <Loader size='large' />
    </Wrapper>
  )
}

const Loader = styled(LoadingCloud)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

interface WrapperProps {
  hasChildren: boolean
  isLoading: boolean
  isInner: boolean
}

const WithoutChildrenStyles = css<WrapperProps>`
  opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
  visibility: ${({ isLoading }) => (isLoading ? 'visible' : 'hidden')};
  background-color: ${({ isInner, theme: { palette } }) =>
    isInner ? palette.white[100] : palette.blue[500]};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ isInner }) => (isInner ? 1 : 20000)};
`

const Wrapper = styled.div<WrapperProps>`
  background-color: ${({ theme }) => theme.palette.white[100]};
  width: 100%;
  height: 100%;

  ${({ hasChildren }) => (hasChildren ? null : WithoutChildrenStyles)}
`

export default LoadingOverlay
