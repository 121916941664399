import ModalsStore from './modalsStoreTypes'

const modalsInitialState: ModalsStore = {
  showCervedPrivacyModal: false,
  showClientsInvitationModal: false,
  showCompanyChanged: false,
  showCompanyPicker: false,
  showDocumentTransform: false,
  showEditAliquotaModal: false,
  showEditEmail: false,
  showEmailConfirmSentModal: false,
  showForfettarioLimitWarningModal: false,
  showForfettarioPrevisionModal: false,
  showInviteClient: false,
  showNewPaymentAccountModal: false,
  showRegenerateRecoveryCodeModal: false,
  showServicePresentationModal: false,
  showSudoModeModal: false,
  showSurveyModal: false,
  showCancelEmailChangeConfirm: false,
  showEditTranchesModal: false,
  showActivateCompanyRegistryModal: {
    show: false,
    plan: undefined,
    title: '',
    subtitle: '',
    accessing1PModule: false,
  },
}

export default modalsInitialState
