import { Draft, PayloadAction } from '@reduxjs/toolkit'

import { BECallStatus } from '../../../../common/types'
import TSPayStore from '../tsPayTypes'

export type SetTSPayActive = {
  active: boolean
}

export const setTSPayActive = (
  state: Draft<TSPayStore>,
  action: PayloadAction<SetTSPayActive>
): void => {
  state.active = action.payload.active
}

export const setTSPay = (
  state: Draft<TSPayStore>,
  action: PayloadAction<TSPayStore>
): Draft<TSPayStore> => {
  return (state = {
    ...state,
    ...action.payload,
    tsPayAnon: {
      ...state.tsPayAnon,
      ...action.payload.tsPayAnon,
    },
  })
}

export const setTSPayFetchStatus = (
  state: Draft<TSPayStore>,
  action: PayloadAction<BECallStatus>
): void => {
  state.fetchStatus = action.payload
}
