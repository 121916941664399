import { PresentationModalService } from '../../../components/layout/components/basePage/components/modals/components/servicePresentationModal/types'
import { GlobalStore } from '../../app/rootReducer'
import {
  getPasswordManagement,
  getPerson,
} from '../auth/person/personSelectors'
import { SurveyType } from '../auth/person/personStoreTypes'
import ModalsStore, {
  ShowActivateCompanyRegistryModal,
} from './modalsStoreTypes'

export const getModals = (state: GlobalStore): ModalsStore => state.modals

export const getIsPasswordModalOpen = (state: GlobalStore): boolean => {
  const { expired, forceChange, manualChange } = getPasswordManagement(state)
  return expired || forceChange || manualChange
}

export const getSurvey = (state: GlobalStore): SurveyType =>
  getPerson(state).survey

export const selectServicePresentationModal = (
  state: GlobalStore
): PresentationModalService | false => {
  return state.modals.showServicePresentationModal
}

export const isClientsInvitationModalVisible = (state: GlobalStore): boolean =>
  state.modals.showClientsInvitationModal

export const getActivationCompanyRegistryModalProps = (
  state: GlobalStore
): ShowActivateCompanyRegistryModal => {
  return state.modals.showActivateCompanyRegistryModal
}
