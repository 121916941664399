import { Permission } from '../../../../common/types'
import {
  CompanyDetailsStore,
  CompanyTsDataStatus,
  PermissionsType,
} from './companyDetailsStoreTypes'

export const permissionsInitialState: PermissionsType = {
  ficSituation: Permission.NONE,
  ficClients: Permission.NONE,
  ficSuppliers: Permission.NONE,
  ficProducts: Permission.NONE,
  ficIssuedDocuments: Permission.NONE,
  ficReceivedDocuments: Permission.NONE,
  ficReceipts: Permission.NONE,
  ficCalendar: Permission.NONE,
  ficArchive: Permission.NONE,
  ficTaxes: Permission.NONE,
  ficStock: Permission.NONE,
  ficCashbook: Permission.NONE,
  ficSettings: Permission.NONE,
  ficEmails: Permission.NONE,
  dicEmployees: Permission.NONE,
  dicTimesheet: Permission.NONE,
  dicSettings: Permission.NONE,
}

const companyDetailsInitialState: CompanyDetailsStore = {
  accountantsCount: 0,
  cervedTokens: 0,
  companyType: '',
  country: '',
  crossCompetenze: false,
  customVars: {},
  companyTsData: {
    status: CompanyTsDataStatus.NOT_STARTED,
    companyRegistryItemId: null,
    workspaceId: null,
    workspaceAttributeId: null,
  },
  defaultWithholdingTax: 0,
  dic: false,
  dicNeedSetup: true,
  enasarco: {
    active: false,
    maximalEnasarco: 0,
  },
  ficBeta: false,
  ficBeta2: false,
  flatRate: false,
  forfettariRevenue: 0,
  functions: {
    archive: false,
    attachPdfToXml: false,
    copilot: false,
    documentAttachments: false,
    eInvoice: false,
    genius: false,
    mailTracking: false,
    paymentNotifications: false,
    priceLists: false,
    receipts: false,
    recurring: false,
    smtp: false,
    sofort: false,
    stock: false,
    subaccounts: false,
    tesseraSanitaria: false,
    tsPay: false,
  },
  gracePeriod: false,
  hasAutomaticRenewal: false,
  hasBlockedNumerations: false,
  hasMed: false,
  invoiceTradingStatus: '',
  isAgyoActive: false,
  isFirstEInvoice: false,
  licenseExpire: '',
  limits: {
    clients: {
      usage: 0,
      limit: 0,
    },
    documents: {
      usage: 0,
      limit: 0,
    },
    products: {
      usage: 0,
      limit: 0,
    },
    suppliers: {
      usage: 0,
      limit: 0,
    },
  },
  needSetup: false,
  needTermsConditionsConfirmation: false,
  pa: {
    eiWithholdingTaxCausal: '',
    eiCassaType: '',
    eiSendingCounter: 0,
  },
  pendingExpensesCount: 0,
  permissions: permissionsInitialState,
  planId: 0,
  regimeForfettario: false,
  role: 'master',
  showFailedIntentOnCreditCardBanner: false,
  signupDate: '',
  taxCode: '',
  useEInvoice: true,
  vatNumber: '',
  vodafone: 0,
  userType: 'OM',
  copilot: {
    active: false,
    token: '',
    acceptedTerms: false,
  },
  isDittaQuater: false,
  companyEmail: '',
}

export default companyDetailsInitialState
