import { Palette } from '@fattureincloud/fic-design-system'

import { isNumeric } from './stringUtils'

// Rimuovere quando DS esporterà i tipi giusti
type bwColors = 'black' | 'white'

const colors = [
  'black',
  'white',
  'grey',
  'blue',
  'indigo',
  'yellow',
  'orange',
  'red',
  'green',
  'fuchsia',
  'pink',
  'cyan',
  'primary',
]

export function isBWColor(color: keyof Palette): color is bwColors {
  return color === 'black' || color === 'white'
}

export const isPaletteColor = (color: string): color is keyof Palette =>
  colors.includes(color)

export const parseDimension = (d: number | string): string =>
  `${d}${typeof d === 'number' || isNumeric(d) ? 'px' : ''}`
