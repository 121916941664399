export const BANNED_COMPANY_NAMES = [
  'la tua azienda',
  'ditta individuale',
  'libero professionista',
  'imprenditore',
  'imprenditrice',
  'ingegnere',
  'geometra',
  'medico',
  'medico libero professionista',
  'architetto',
  'architetta',
  'pensionato',
  'pensionata',
  'associazione',
  'associazione professionale',
  'studio medico',
  'medico chirurgo',
  'medico di famiglia',
  'medico dentista',
  'dentista',
  'avvocato',
  'commercialista',
  'idraulico',
  'elettricista',
  'impresa individuale',
  'impresa edile',
  'impresa artigiana',
  'impresa',
  'artigiano',
  'medico legale',
  'veterinario',
  'veterinaria',
  'archeologo',
  'archeologa',
  'odontoiatra',
  'impianti elettrici',
  'privato',
]
