import { emailHasError, emailHasPecDomain } from '../emailUtils'
import { validateLength } from './stringValidators'
import { BaseValidatorParams, ValidationResult } from './types'
import { Validation } from './validatorResultMaker'

export type EmailValidatorParams = BaseValidatorParams & {
  checkPEC?: boolean
  checkCurrent?: string
}

/**
 * Email validator
 * @param {string} email - The email to validate
 * @param {ValidatorParams} [params] - The validation parameters
 * @return {ValidationResult}
 */
export const validateEmail = (
  email: string,
  params?: EmailValidatorParams
): ValidationResult => {
  if (email === '' && params?.allowEmpty) return Validation.success()
  if (params?.minLength || params?.maxLength) {
    const lengthCheck = validateLength(email, params)
    if (!lengthCheck.valid) return lengthCheck
  }

  if (params?.checkCurrent && params.checkCurrent === email) {
    Validation.error(
      'Il nuovo indirizzo e-mail deve essere diverso da quello attuale.'
    )
  }

  const error = emailHasError(email)
  if (error) return Validation.error(error)

  if (params?.checkPEC) {
    const pecWarn = emailHasPecDomain(email)
    if (pecWarn) return Validation.warning(pecWarn)
  }

  return Validation.success()
}

/**
 * Multi email validator, must be comma separated values
 * @param {string} emails - The emails to validate
 * @param {ValidatorParams} [params] - The validation parameters
 * @return {ValidationResult}
 */
export const validateMultiEmails = (
  emails: string,
  params?: EmailValidatorParams
): ValidationResult => {
  if (emails === '' && params?.allowEmpty) return Validation.success()

  const emailList: string[] = emails.split(',').reduce((acc, e) => {
    if (e.trim() !== '') {
      acc.push(e.trim())
    }
    return acc
  }, [] as string[])

  if (emailList.length === 0) {
    return Validation.error('Inserisci almeno un indirizzo e-mail.')
  }

  for (const email of emailList) {
    // allowEmpty is false because we already excluded empty values
    const emailCheck = validateEmail(email, { ...params, allowEmpty: false })
    if (!emailCheck.valid) return emailCheck
  }

  return Validation.success()
}
