import { Icon, Toast } from '@fattureincloud/fic-design-system'
import { faCloudUploadAlt } from '@fortawesome/pro-duotone-svg-icons'
import {
  faFile,
  faFileArchive,
  faFileCode,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons'
import mime from 'mime'
import PropTypes from 'prop-types'
import React, { Component, Fragment, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import styled, { css } from 'styled-components'

import FICColors from '../../general/js/FICColors'
import FICRestV2 from '../../network/js/FICRestV2'

const typesMap = {
  unknown: {
    mimeTypes: ['*'],
    extensions: [],
    icon: faFile,
  },
  pdf: {
    mimeTypes: ['application/pdf'],
    extensions: ['pdf'],
    icon: faFilePdf,
  },
  image: {
    mimeTypes: ['image/png', 'image/jpeg', 'image/gif'],
    extensions: ['png', 'gif', 'jpg', 'jpeg'],
    icon: faFileImage,
  },
  zip: {
    mimeTypes: [
      'application/zip',
      'application/octet-stream',
      'application/x-zip-compressed',
      'multipart/x-zip',
    ],
    extensions: ['zip'],
    icon: faFileArchive,
  },
  archive: {
    mimeTypes: [
      'application/zip',
      'application/octet-stream',
      'application/x-zip-compressed',
      'multipart/x-zip',
      'application/x-rar-compressed',
    ],
    extensions: ['zip'],
    icon: faFileArchive,
  },
  text: {
    mimeTypes: [
      'text/plain',
      'application/rtf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.oasis.opendocument.text',
    ],
    extensions: ['doc', 'docx'],
    icon: faFileWord,
  },
  document: {
    mimeTypes: [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.oasis.opendocument.text',
    ],
    extensions: ['doc', 'docx', 'docm'],
    icon: faFileWord,
  },
  spreadsheet: {
    mimeTypes: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.oasis.opendocument.spreadsheet',
    ],
    extensions: ['xls', 'xlsx', 'xlsm'],
    icon: faFileExcel,
  },
  presentation: {
    mimeTypes: [
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.oasis.opendocument.presentation',
    ],
    extensions: ['ppt', 'pptx', 'odp'],
    icon: faFilePowerpoint,
  },
  xml: {
    mimeTypes: ['text/xml', 'application/xml'],
    extensions: ['xml'],
    icon: faFileCode,
  },
  csv: {
    mimeTypes: [
      'text/csv',
      'text/plain',
      'text/x-csv',
      'application/vnd.ms-excel',
      'application/csv',
      'application/x-csv',
      'text/comma-separated-values',
      'text/x-comma-separated-values',
      'text/tab-separated-values',
    ],
    extensions: ['csv'],
    icon: faFileCsv,
  },
}

const getIconForMimeType = type => {
  const typeName = Object.keys(typesMap).find(typeName => {
    return typesMap[typeName].mimeTypes.includes(type)
  })
  if (typeName) {
    return typesMap[typeName].icon
  }
  return faFile
}

class FICFileUploader extends Component {
  constructor(props) {
    super(props)
    const { allowedExtensions, allowedTypes } = props
    let mimeTypes = []
    let extensions = []
    allowedTypes.forEach(type => {
      if (typesMap.hasOwnProperty(type)) {
        mimeTypes = [...mimeTypes, ...typesMap[type].mimeTypes]
        extensions = [...extensions, ...typesMap[type].extensions]
      } else {
        mimeTypes.push(type)
      }
    })
    mimeTypes = mimeTypes.join(', ')
    extensions = [...extensions, ...allowedExtensions].join(', ').toUpperCase()
    this.state = {
      accept: mimeTypes,
      extensions: extensions,
      selectedFiles: [],
      uploading: false,
    }
  }

  componentDidMount() {
    if (
      this.props.preloadedFiles.length > 0 &&
      this.state.selectedFiles.length === 0
    ) {
      this.setState({ selectedFiles: this.props.preloadedFiles })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.preloadedFiles) !==
        JSON.stringify(this.props.preloadedFiles) &&
      this.state.selectedFiles.length === 0 &&
      this.props.preloadedFiles.length > 0
    ) {
      this.setState({ selectedFiles: this.props.preloadedFiles })
    }
    // Make sure to revoke the data uris to avoid memory leaks
    setTimeout(() => {
      this.state.selectedFiles.forEach(file =>
        URL.revokeObjectURL(file.preview)
      )
    }, 100)
  }

  handleFileDrop = (accepted, rejected) => {
    const props = this.props
    if (accepted.length > 0) {
      this.setState({ uploading: true })

      if (props.uploadPath) {
        const alertId = Toast.info('Caricamento in corso....')
        FICRestV2.post({
          request: props.uploadPath,
          formData: {
            filename: accepted[0].name,
            attachment: accepted[0],
          },
          onSuccess: response => {
            Toast.dismiss(alertId)
            if (response.data.attachment_token) {
              const newFiles = accepted.slice()

              newFiles[0].attachment_token = response.data.attachment_token
              newFiles[0].preview = URL.createObjectURL(accepted[0])

              this.setState(
                { selectedFiles: newFiles, uploading: false },
                () => {
                  if (props.onChange) {
                    props.onChange(this.state.selectedFiles)
                  }
                }
              )
            }
            if (props.onUploadSuccess) {
              props.onUploadSuccess(response)
            }
          },
          onError: response => {
            Toast.dismiss(alertId)
            Toast.error('Questo file non può essere allegato.')
            this.setState({ selectedFiles: [], uploading: false }, () => {
              if (props.onChange) {
                props.onChange(this.state.selectedFiles)
              }
            })
            if (props.onUploadError) {
              props.onUploadError(response)
            }
          },
        })
      }
    } else if (rejected.length > 0) {
      if (rejected.length > 1) {
        Toast.error('Puoi caricare un solo file.')
      } else {
        if (rejected[0].size > props.maxSize) {
          Toast.error(
            'Questo file supera la dimensione massima consentita (' +
              Math.round(props.maxSize / 1024 / 1024) +
              'MB).'
          )
        } else {
          Toast.error('Questo tipo di file non è supportato.')
        }
      }
    }
    if (props.onDrop) {
      props.onDrop(accepted, rejected)
    }
  }

  removeFile = (e, idx) => {
    e.stopPropagation()

    const props = this.props
    const newFiles = this.state.selectedFiles.slice()

    newFiles.splice(idx, 1)
    this.setState(
      {
        selectedFiles: newFiles,
      },
      () => {
        if (props.onChange) {
          props.onChange(this.state.selectedFiles)
        }
      }
    )
  }

  render() {
    const { accept, extensions, selectedFiles, uploading } = this.state
    const {
      allowedExtensions,
      allowedTypes,
      className,
      disabled,
      onDrop,
      onUploadError,
      onUploadSuccess,
      preloadedFiles,
      uploadPath,
      ...innerProps
    } = this.props

    return (
      <StyledDropzone
        className={className}
        accept={accept}
        extensions={extensions}
        selectedFiles={selectedFiles}
        onDrop={this.handleFileDrop}
        multiple={false}
        removeFile={this.removeFile}
        uploading={uploading}
        disabled={disabled}
        {...innerProps}
      />
    )
  }
}

function StyledDropzone({
  accept,
  className,
  customIcon,
  disabled,
  extensions,
  multiple,
  onDrop,
  previewMaxHeight,
  removeFile,
  selectedFiles,
  text,
  uploading,
}) {
  const {
    getInputProps,
    getRootProps,
    isDragAccept,
    isDragActive,
    isDragReject,
  } = useDropzone({ accept: accept, multiple, onDrop, disabled: disabled })

  const uploadFileRef = useRef()
  const [width, setWidth] = useState(1000000)

  useEffect(() => {
    if (uploadFileRef.current && uploadFileRef.current.offsetWidth < width) {
      setWidth(uploadFileRef.current.offsetWidth)
    }
  }, [width])

  return (
    <Container
      {...getRootProps({
        className,
        isDragActive,
        isDragAccept,
        isDragReject,
        disabled,
      })}
    >
      <input {...getInputProps()} />
      <div className='description' ref={uploadFileRef}>
        {uploading ? (
          <img src='/img/ajax-loader.gif' style={{ width: 150 }} />
        ) : (
          <Fragment>
            <div>
              {customIcon ?? <Icon icon={faCloudUploadAlt} size={'2x'} />}
            </div>
            {text ? (
              <div>{text}</div>
            ) : (
              <Fragment>
                <div>
                  Puoi allegare un'immagine/file trascinandola o premendo qui.
                </div>
                {extensions && extensions.length > 0 && (
                  <div>Sono accettati file di tipo {extensions}.</div>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
      {selectedFiles.length > 0 && (
        <div className='files'>
          {selectedFiles.map((file, idx) => (
            <FileItem
              key={idx}
              idx={idx}
              file={file}
              width={width}
              onRemoveClick={removeFile}
              previewMaxHeight={previewMaxHeight}
              disabled={disabled}
            />
          ))}
        </div>
      )}
    </Container>
  )
}

const FileItem = ({ disabled, file, idx, onRemoveClick, width, ...props }) => {
  let fileName = file.name || file.substring(file.lastIndexOf('/') + 1)
  fileName =
    fileName.indexOf('?') > 0
      ? fileName.substring(0, fileName.indexOf('?'))
      : fileName

  return (
    <FileItemContainer onClick={e => e.stopPropagation()} {...props}>
      {file instanceof File && !file.attachment_token ? (
        <FileItemLoading
          name='circle-notch'
          size={20}
          color={FICColors.primary}
          spin
        />
      ) : (
        <Fragment>
          {!disabled && (
            <FileItemIcon
              onClick={e => onRemoveClick(e, idx)}
              icon={faTimesCircle}
            />
          )}
          <FilePreviewWrapper previewMaxHeight={props.previewMaxHeight}>
            {typeof file === 'string' &&
            mime.getType(fileName) &&
            mime.getType(fileName).includes('image') ? (
              <img src={file} onClick={e => e.stopPropagation()} />
            ) : file.type && file.type.includes('image') ? (
              file.preview ? (
                <img src={file.preview} onClick={e => e.stopPropagation()} />
              ) : (
                <FileItemIcon icon={getIconForMimeType(file.type)} size={40} />
              )
            ) : (
              <FileItemIcon
                icon={getIconForMimeType(file.type || mime.getType(file))}
                size={40}
              />
            )}
          </FilePreviewWrapper>
          <FileItemName width={width}>{fileName}</FileItemName>
        </Fragment>
      )}
    </FileItemContainer>
  )
}

FICFileUploader.propTypes = {
  allowedTypes: PropTypes.array,
  allowedExtensions: PropTypes.array,
  multiple: PropTypes.bool,
  maxSize: PropTypes.number,
  onDrop: PropTypes.func,
  uploadPath: PropTypes.string,
  onUploadSuccess: PropTypes.func,
  onUploadError: PropTypes.func,
  preloadedFiles: PropTypes.array,
  disabled: PropTypes.bool,
  previewMaxHeight: PropTypes.number,
}

FICFileUploader.defaultProps = {
  allowedTypes: [],
  allowedExtensions: [],
  multiple: false,
  maxSize: undefined,
  onDrop: undefined,
  uploadPath: undefined,
  onUploadSuccess: undefined,
  onUploadError: undefined,
  preloadedFiles: [],
  disabled: undefined,
  previewMaxHeight: undefined,
}

/*
const FICFileUploader = styled(FICFileUploaderImpl)`
  width: 100%;
  height: 200px;
  border: 2px dashed #d5d5d563;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  color: ${props => props.disabled ? FICColors.textDisabled : undefined};
  text-align: center !important;
  div.description {
    vertical-align: middle;
  }
  div.files {
    vertical-align: middle;
    margin-top: 10px;
    >div {
      margin-top: 16px;
    }
  }
  &.dropzone-accept {
    border-color: #007ce4;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 124, 228, 0.1);
    b.fa.fa-cloud-upload {
      color: #007ce4;
    }
  }
  &.dropzone-reject {
    border-color: #f46e65;
    box-shadow: inset 0 1px 1px rgba(240, 65, 52, .1), 0 0 0 3px rgba(240, 65, 52, .1);
    b.fa.fa-cloud-upload {
      color: #f46e65;
    }
  }
`
 */

const Container = styled.div`
  width: 100%;
  border: 2px dashed #d5d5d563;
  border-radius: 8px;
  display: table;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  ${props =>
    props.disabled &&
    css`
      color: ${FICColors.textDisabled};
      cursor: not-allowed;
    `}

  ${props =>
    (props.isDragActive || props.isDragAccept) &&
    css`
      border-color: #007ce4;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 0 3px rgba(0, 124, 228, 0.1);
      b.fa.fa-cloud-upload {
        color: #007ce4;
      }
    `};

  ${props =>
    props.isDragReject &&
    css`
      border-color: #f46e65;
      box-shadow: inset 0 1px 1px rgba(240, 65, 52, 0.1),
        0 0 0 3px rgba(240, 65, 52, 0.1);
      b.fa.fa-cloud-upload {
        color: #f46e65;
      }
    `};

  div.description {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
  div.files {
    display: table-row;
    vertical-align: middle;
    margin-top: 10px;
    text-align: center;
    > div {
      text-align: center;
      margin-top: 16px;
    }
  }
`

const FileItemLoading = styled(Icon)`
  display: inline-block;
  vertical-align: middle;
`

const FileItemIcon = styled(Icon)`
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
`

const FileItemName = styled.div`
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => props.width}px;
`

const FileItemContainer = styled.div`
  cursor: default;
  display: inline-block;
`

const FilePreviewWrapper = styled.div`
  width: 100px;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  ${props =>
    props.previewMaxHeight &&
    `
    max-height: ${props.previewMaxHeight}px;
  `}
`

export default FICFileUploader
