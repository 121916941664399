import { validateTaxCode, validateVatNumber } from '.'
import {
  TaxCodeOrVatNumberValidationResult,
  ValidationResult,
  ValidatorParams,
} from './types'
import { Validation } from './validatorResultMaker'

/**
 * Length validator
 * @param {string} s - The string to validate
 * @param {ValidatorParams} [params] - The validation parameters
 * @return {ValidationResult}
 */
export const validateLength = (
  s: string,
  params?: ValidatorParams
): ValidationResult => {
  const { allowEmpty, maxLength, minLength } = params || {}

  if (allowEmpty && s === '') return Validation.success()

  if (!allowEmpty && s === '') {
    return Validation.error('Il campo non può essere vuoto.')
  }

  if (minLength && s.length < minLength) {
    return Validation.error(
      `Il campo deve contenere almeno ${minLength} caratteri.`
    )
  }
  if (maxLength && s.length > maxLength) {
    return Validation.error(
      `Il campo può contenere al massimo ${maxLength} caratteri.`
    )
  }
  return Validation.success()
}

/**
 * Tax code OR vat number validator
 * @param {string} taxCode - The tax code to validate
 * @param {ValidatorParams} [params] - The validation parameters
 * @return {ValidationResult}
 */
export const validateTaxCodeOrVatNumber = (
  taxCode: string,
  params?: ValidatorParams
): ValidationResult => {
  const check = validateVatNumber(taxCode, params)
  if (check.valid) {
    return check
  }
  return validateTaxCode(taxCode, params)
}

/**
 * Checks if the value passed is a tax code or a vat number (italian)
 * @param {string} value - The value to validate
 * @param {ValidatorParams} [params] - The validation parameters
 * @return {ValidationResult}
 */
export const checkIfTaxCodeOrVatNumber = (
  value: string,
  params?: ValidatorParams
): TaxCodeOrVatNumberValidationResult => {
  const vatNumberCheck = validateVatNumber(value, params)
  if (vatNumberCheck.valid) {
    return { ...vatNumberCheck, type: 'vatNumber' }
  }
  const taxCodeCheck = validateTaxCode(value, params)
  return { ...taxCodeCheck, type: 'taxCode' }
}
