import { createSelector } from '@reduxjs/toolkit'

import { GlobalStore } from '../../../app/rootReducer'
import {
  getConnectionsArray,
  getCurrentConnection,
} from '../connections/connectionsSelectors'
import { getCompaniesArray, getIsManagingCompany } from './companySelectors'

export const getAccountantId = (state: GlobalStore) =>
  getCurrentConnection(state).controllerId

export const getAccountant = (state: GlobalStore) => {
  const accountantId = getAccountantId(state)
  return getCompaniesArray(state).find(c => c.id === accountantId)
}

export const getIsAccountant = (state: GlobalStore) =>
  !!getCurrentConnection(state).controllerId

export const getAccountantExists = (state: GlobalStore) =>
  getConnectionsArray(state).reduce(
    (exists, c) => exists || !!c.controllerId,
    false
  )

export const getAccountantConnection = createSelector(
  [getAccountantId, getConnectionsArray],
  (accountantId, connections) =>
    connections.find(c => c.controlledId === accountantId)
)

export const getIsAccountantManagingClientCompany = (
  state: GlobalStore
): boolean => {
  const { controlledId, controllerId } = getCurrentConnection(state)
  return (
    getIsAccountant(state) &&
    getIsManagingCompany(state) &&
    controllerId !== controlledId
  )
}
