import FICDateTime from '../../../common/dateTime'
import { version } from '../../../common/environment'
import LocalStorageStore, { localStorageKeys } from './localStorageStoreTypes'
import { localStorageFindOrCreate } from './localStorageUtils'

const forceOverrideFields: (keyof LocalStorageStore)[] = ['version']
const currentMonth = new Date().getMonth() + 1

const defaultValues: LocalStorageStore = {
  hasuraAccessToken: '',
  accessToken: '',
  accountantCall: null,
  accountantCallBannerVisible: true,
  accountantNotifications: 0,
  accountantWebinars: 0,
  activeWebinars: [],
  bankReconciliationWaitingActivation: {},
  emailConfirmationModalLastDismiss: null,
  expensesListSearchText: '',
  expensesListSelectedMonths: [currentMonth],
  ficBannerMessage: '',
  fiscalYear: FICDateTime.currentYear,
  hasAutomaticRenewal: false,
  hideSignUpPushNotification: false,
  invoicesAnchor: '#invoices',
  isPushNotificationsEnabled: false,
  isSnowEnabled: false,
  lastAccessTokenAction: 'changedCompany',
  lastChangedAtFiscalYear: 0,
  lastDeletedConnectionId: 0,
  lastOpenedSidebarMenu: '',
  lastSelectedKey: window.location.pathname + window.location.hash,
  maintenance: {
    startAt: '',
    message: '',
  },
  marketingModalNextAppearance: '',
  newWebinarsCount: 0,
  nextEditAliquotaAskDate: 0,
  nextPrivacyAskDate: 0,
  notNowPrivacyCount: 0,
  pendingExpensesRegistration: [],
  sessionId: '',
  sidebarCounters: {
    bankStatementsCounter: 0,
    calendarCounter: 0,
    cessioneCreditoCounter: 0,
    expensesCounter: 0,
    primaNotaCounter: 0,
    ribaCounter: 0,
    solicitsCounter: 0,
    taxesCounter: 0,
    tsPayCounter: 0,
  },
  totalNotifications: 0,
  unreadNotifications: 0,
  upcomingWebinar: null,
  version,
  wantsManageCompany: true,
  // Questo valore ci permette di mantenere salvati i dati nel flusso di registrazione in bulk di documenti da registrare, è necessario che sia a localStorage in quanto più tab devono comunicare
  autoImportData: {
    errors: [],
    successCount: 0,
    processing: false,
    pendingExpensesCount: 0,
  },
}

const localStorageInitialState: LocalStorageStore = (
  Object.keys(defaultValues) as localStorageKeys[]
).reduce(
  (initalState, key) => ({
    ...initalState,
    [key]: localStorageFindOrCreate(
      key,
      defaultValues[key],
      forceOverrideFields.includes(key)
    ),
  }),
  {} as LocalStorageStore
)

export default localStorageInitialState
