import companyDetailsSlice from './companyDetailsSlice'

export const {
  decrementUsageLimit,
  incrementUsageLimit,
  setAccountantsCount,
  setCompanyCountry,
  setCompanyEmail,
  setCompanyTsDataStatus,
  setCompanyType,
  setCopilotAttributes,
  setEiSendingCounter,
  setEnasarco,
  setForfettariRevenue,
  setGracePeriod,
  setHasBlockedNumerations,
  setHasMed,
  setInvoiceTradingStatus,
  setIsAgyoActive,
  setIsRegimeForfettario,
  setLicenseExpiringDate,
  setNeedTermsConditionsConfirmation,
  setPlanId,
  setUsageLimit,
  setVatNumber,
} = companyDetailsSlice.actions

export const companyDetails = companyDetailsSlice.reducer
