import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'

import Api from '../../../../../common/network/Api'
import { BECallStatus } from '../../../../../common/types'
import { GlobalStore } from '../../../../app/rootReducer'
import { PersonStore } from '../personStoreTypes'

const sendEmailConfirmation = createAsyncThunk<
  true,
  void,
  { rejectValue: false }
>('person/sendEmailConfirmation', async (_, thunkApi) => {
  try {
    const { auth } = thunkApi.getState() as GlobalStore
    const first =
      auth.person.emailConfirmationState.details?.neverAskedConfirmation

    await Api.post({
      request: `/email_confirmation/${
        first ? 'send_first_email' : 'resend_email'
      }`,
    })
    return true
  } catch (e) {
    return thunkApi.rejectWithValue(false)
  }
})

// Since redux thunkApi doesn't support optional parameters, we need to create a new thunk to handle the callback
export const sendEmailConfirmationWithCallback = createAsyncThunk<
  true,
  () => void,
  { rejectValue: false }
>('person/sendEmailConfirmation', async (onSuccess, thunkApi) => {
  try {
    const { auth } = thunkApi.getState() as GlobalStore
    const first =
      auth.person.emailConfirmationState.details?.neverAskedConfirmation

    await Api.post({
      request: `/email_confirmation/${
        first ? 'send_first_email' : 'resend_email'
      }`,
    }).then(() => onSuccess())
    return true
  } catch (e) {
    return thunkApi.rejectWithValue(false)
  }
})

export const addSendEmailConfirmationCases = (
  builder: ActionReducerMapBuilder<PersonStore>
): void => {
  builder
    .addCase(sendEmailConfirmation.pending, state => {
      state.sendEmailConfirmStatus = BECallStatus.LOADING
    })
    .addCase(sendEmailConfirmation.fulfilled, state => {
      state.sendEmailConfirmStatus = BECallStatus.SUCCESS
    })
    .addCase(sendEmailConfirmation.rejected, state => {
      state.sendEmailConfirmStatus = BECallStatus.FAILED
    })
}

export default sendEmailConfirmation
