import React from 'react'

import {
  InformativaDatasetCerved,
  InformativaNoteLegaliCerved,
  InformativaPrivacyCerved,
} from './config'
import { Wrapper, WrapperSecondText } from './styled'

interface Props {
  small?: boolean
}

export const CervedPrivacyContent = ({ small = false }: Props): JSX.Element => {
  return (
    <Wrapper small={small}>
      <>
        Con riferimento alle aziende, i dati mostrati sono forniti da
        Modefinance Srl, società di informazione commerciale autorizzata.
        Consulta <InformativaPrivacyCerved />
      </>
      <WrapperSecondText small={small}>
        Con riferimento alle Pubbliche Amministrazioni, i dati sono ricavati
        dall’Indice dei domicili digitali delle Pubbliche Aministrazioni (IPA) e
        dei gestori di pubblici servizi realizzato da AgiD. Consulta{' '}
        <InformativaNoteLegaliCerved /> e <InformativaDatasetCerved />.
      </WrapperSecondText>
    </Wrapper>
  )
}
