import { Permission } from '../../../../common/types'

export interface LimitType {
  usage: number
  limit: number
}

export interface CopilotAttributesType {
  token: string
  acceptedTerms: boolean
}

export enum CompanyTsDataStatus {
  NOT_STARTED = 'not_started',
  NEED_API_KEYS_CREATION = 'need_api_keys_creation',
  PENDING = 'pending',
  NEED_API_TOKEN = 'need_api_token',
  COMPLETED = 'completed',
}

export interface CompanyTsData {
  status: CompanyTsDataStatus
  companyRegistryItemId: string | null
  workspaceId: string | null
  workspaceAttributeId: string | null
}

export interface CompanyDetailsStore {
  accountantsCount: number
  cervedTokens: number
  companyType: string
  country: string
  crossCompetenze: boolean
  customVars: CustomVarsType
  companyTsData: CompanyTsData
  defaultWithholdingTax: number
  dic: boolean
  dicNeedSetup: boolean
  enasarco: EnasarcoType
  ficBeta: boolean
  ficBeta2: boolean
  flatRate: boolean
  forfettariRevenue: number
  functions: FunctionsType
  gracePeriod: boolean
  hasAutomaticRenewal: boolean
  hasBlockedNumerations: boolean
  hasMed: boolean
  invoiceTradingStatus: string
  isAgyoActive: boolean
  isFirstEInvoice: boolean
  licenseExpire: string
  limits: {
    clients: LimitType
    documents: LimitType
    products: LimitType
    suppliers: LimitType
  }
  needSetup: boolean
  needTermsConditionsConfirmation: boolean
  pa: PAType
  pendingExpensesCount: number
  permissions: PermissionsType
  planId: number
  regimeForfettario: boolean
  role: 'master' | 'subaccount' | 'only_employee'
  showFailedIntentOnCreditCardBanner: boolean
  signupDate: string
  taxCode: string
  useEInvoice: boolean
  userType: string
  vatNumber: string
  vodafone: number
  isDittaQuater: boolean
  companyEmail: string
  copilot: CopilotAttributesType
}

export interface CustomVarsType {
  premiumSubaccounts?: string
  premiumMailtrack?: string
  premiumRecurring?: string
  premiumRiba?: string
  premiumSolicits?: string
}

interface PAType {
  eiSendingCounter: number
  eiCassaType: string | null
  eiWithholdingTaxCausal: string | null
}

export interface FunctionsType {
  archive: boolean
  attachPdfToXml: boolean
  copilot: boolean
  documentAttachments: boolean
  eInvoice: boolean
  genius: boolean
  mailTracking: boolean
  paymentNotifications: boolean
  priceLists: boolean
  receipts: boolean
  recurring: boolean
  smtp: boolean
  sofort: boolean
  stock: boolean
  subaccounts: boolean
  tesseraSanitaria: boolean
  tsPay: boolean
}

export interface EnasarcoType {
  active: boolean
  maximalEnasarco: number
}

export type DetailedPermissionType = Permission | 'detailed'

export type FicIssuedDocumentDetailedKeys =
  | 'quotes'
  | 'proformas'
  | 'invoices'
  | 'receipts'
  | 'deliveryNotes'
  | 'creditNotes'
  | 'orders'
  | 'workReport'
  | 'supplierOrders'
  | 'selfInvoices'

export type FicIssuedDocumentsDetailedType = {
  [key in FicIssuedDocumentDetailedKeys]: Permission
}

export type FICPermissions =
  | 'ficSituation'
  | 'ficClients'
  | 'ficSuppliers'
  | 'ficProducts'
  | 'ficIssuedDocuments'
  | 'ficReceivedDocuments'
  | 'ficReceipts'
  | 'ficCalendar'
  | 'ficArchive'
  | 'ficTaxes'
  | 'ficStock'
  | 'ficCashbook'
  | 'ficSettings'
  | 'ficEmails'

export type DICPermissions = 'dicEmployees' | 'dicTimesheet' | 'dicSettings'
export type FicIssuedDocumentsDetailedPermission = 'ficIssuedDocumentsDetailed'

export type PermissionKey =
  | FICPermissions
  | DICPermissions
  | FicIssuedDocumentsDetailedPermission

export type PermissionsType = {
  ficSituation: Permission
  ficClients: Permission
  ficSuppliers: Permission
  ficProducts: Permission
  ficIssuedDocuments: DetailedPermissionType
  ficReceivedDocuments: Permission
  ficReceipts: Permission
  ficCalendar: Permission
  ficArchive: Permission
  ficTaxes: Permission
  ficStock: Permission
  ficCashbook: Permission
  ficSettings: Permission
  ficEmails: Permission
  dicEmployees: Permission
  dicTimesheet: Permission
  dicSettings: Permission
  ficIssuedDocumentsDetailed?: FicIssuedDocumentsDetailedType
}

export type PermissionsSelector<T extends PermissionKey> = {
  [K in T]: PermissionsType[K]
}

interface PAType {
  eiSendingCounter: number
  eiCassaType: string | null
  eiWithholdingTaxCausal: string | null
}
