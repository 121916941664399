import 'react-app-polyfill/ie11'
import 'core-js'
import 'regenerator-runtime/runtime'
import 'abortcontroller-polyfill'
import 'broadcastchannel-polyfill'

import PromisePolyfill from 'es6-promise'
import lodash from 'lodash'
import ResizeObserver from 'resize-observer-polyfill'

import { replaceSpecialChars } from './common/utils/stringUtils'

// global was provided by webpack
// and it's used by some old deps
window.global ||= window
// lodash also was provided by webpack
// and it's used by some old js files
// like old_js/invoices/invoices.js
window._ ||= lodash

if (!('scrollBehavior' in document.documentElement.style)) {
  import('scroll-behavior-polyfill')
}

PromisePolyfill.polyfill()

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver
}

if (!String.prototype.normalize) {
  Object.defineProperty(String.prototype, 'normalize', {
    enumerable: false,
    configurable: true,
    writable: true,
    value: function normalize() {
      return replaceSpecialChars(this)
    },
  })
}
