import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { GlobalParamsPayload, setFromApi } from '../../app/actions/setFromApi'
import { setBankReconciliationActive } from './reducers/setBankReconciliationReducer'
import { setDigitalPOSActive } from './reducers/setDigitalPOSReducer'
import {
  setChargeAmounts,
  setIncomingPaymentsActive,
} from './reducers/setIncomingPaymentsReducer'
import { setOutgoingPaymentsActive } from './reducers/setOutgoingPaymentsReducer'
import {
  setTSPayAnon,
  setTSPayAnonActive,
} from './reducers/setTSPayAnonReducer'
import {
  setTSPay,
  setTSPayActive,
  setTSPayFetchStatus,
} from './reducers/setTSPayReducer'
import { addActivateOutgoingPaymentsCases } from './thunks/activateOutgoingPayments'
import tsPayInitialState from './tsPayInitialState'

const tsPaySlice = createSlice({
  name: 'tsPay',
  initialState: tsPayInitialState,
  reducers: {
    setBankReconciliationActive,
    setChargeAmounts,
    setDigitalPOSActive,
    setIncomingPaymentsActive,
    setOutgoingPaymentsActive,
    setTSPay,
    setTSPayAnon,
    setTSPayAnonActive,
    setTSPayActive,
    setTSPayFetchStatus,
  },
  extraReducers: builder => {
    addActivateOutgoingPaymentsCases(builder)
    builder.addCase(
      setFromApi,
      (state, action: PayloadAction<GlobalParamsPayload>) => {
        return {
          ...state,
          ...action.payload.tsPay,
        }
      }
    )
  },
})

export default tsPaySlice
