import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import { isProduction } from '../common/environment'
import createRootReducer, { GlobalStore } from './app/rootReducer'

const store = configureStore<GlobalStore>({
  reducer: createRootReducer(),
  devTools: !isProduction,
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch

export default store
