import { Currency, Language, PaymentTerm, Vat } from '.'
import { FICEntity } from './entities'

export const documentsTypesArray = [
  'credit_note',
  'delivery_note',
  'expense',
  'invoice',
  'order',
  'passive_credit_note',
  'passive_delivery_note',
  'proforma',
  'quote',
  'receipt',
  'self_invoice',
  'self_own_invoice',
  'self_supplier_invoice',
  'supplier_order',
  'work_report',
] as const

export enum DocumentType {
  CREDIT_NOTE = 'credit_note',
  DELIVERY_NOTE = 'delivery_note',
  EXPENSE = 'expense',
  INVOICE = 'invoice',
  ORDER = 'order',
  PASSIVE_CREDIT_NOTE = 'passive_credit_note',
  PASSIVE_DELIVERY_NOTE = 'passive_delivery_note',
  PROFORMA = 'proforma',
  QUOTE = 'quote',
  RECEIPT = 'receipt',
  SELF_INVOICE = 'self_invoice',
  SELF_OWN_INVOICE = 'self_own_invoice',
  SELF_SUPPLIER_INVOICE = 'self_supplier_invoice',
  SUPPLIER_ORDER = 'supplier_order',
  WORK_REPORT = 'work_report',
}

export enum EIStatus {
  DISCARDED = 'discarded',
  REJECTED = 'rejected',
  NO_RESPONSE = 'no_response',
}

// Generic Issued Document, to use when not every information is returned from the API
export type FICGenericDocument = {
  amountGross: number
  date: string
  entity: FICEntity
  id: number
  number?: number
  numeration?: string | null
  tranches: FICGenericTranche[]
  type: DocumentType | null
}

export type FICGenericTranche = {
  amount: number
  id: number
  dueDate: string
}

export type FICCompleteTranche = FICGenericTranche & {
  eiRaw: Record<string, unknown> | null
  forfettariRevenue: number
  paidDate: string | null
  paymentAccount: FICPaymentAccount | null
  paymentTerms: PaymentTerm
  status: PaymentStatus
}

export type FICBasePaymentAccount = {
  id: number | null
  name: string
  virtual?: boolean
}

export type FICPaymentAccount = FICBasePaymentAccount & {
  accountId?: string | null //id of tsPay account
  balance?: number | null
  bankId?: string | null
  consentExpireDate?: string | null
  consentId?: string | null
  fromTsPay: boolean
  iban?: string | null
  id: number | null
  isLinkedToTsPay: boolean
  lastBalanceUpdate?: string | null
  lastUsed: boolean
  name: string
  order: number
  productCode?: string | null
  reconciliationActive: boolean
  resourceId?: string | null
  sia?: string | null
  type?: 'standard' | 'bank'
  virtual: boolean
  logoUrl?: string | null
  favorite?: boolean
}

export enum PaymentStatus {
  PAID = 'paid',
  NOT_PAID = 'not_paid',
  REVERSED = 'reversed',
}

export type FICDocumentItem = {
  productId: number
  code: string
  name: string
  measure: string
  category: string
  id: number
  applyWithholdingTaxes: boolean
  discount: number
  discountHighlight: boolean
  inDn: boolean
  qty: number
  netPrice: number
  vat: Vat
  stock: boolean
  description: string
  grossPrice: number
  notTaxable: boolean
}

export type FICIssuedDocument = {
  id: number
  type: DocumentType
  year: number
  numeration: string
  subject: string
  visibleSubject: string
  rcCenter: string
  amountRivalsa: number
  amountRivalsaTaxable: number
  amountGlobalCassaTaxable: number
  amountCassa: number
  amountCassaTaxable: number
  amountCassa2: number
  amountCassa2Taxable: number
  amountWithholdingTax: number
  amountWithholdingTaxTaxable: number
  amountOtherWithholdingTax: number
  amountEnasarcoTaxable: number
  amountOtherWithholdingTaxTaxable: number
  eiCassaType: string
  eiCassa2Type: string
  eiWithholdingTaxCausal: string
  eiOtherWithholdingTaxType: string
  eiOtherWithholdingTaxCausal: string
  stampDuty: number
  useGrossPrices: boolean
  eInvoice: boolean
  deliveryNote: boolean
  accompanyingInvoice: boolean
  amountNet: number
  amountVat: number
  amountGross: number
  amountDueDiscount: number
  hMargins: number
  vMargins: number
  showPaymentMethod: boolean
  showPayments: boolean
  showTotals: string
  showNotificationButton: boolean
  isMarked: boolean
  createdAt: string
  updatedAt: string
  attachPdfToXml: boolean
  entity: FICEntity
  date: string
  number: number
  currency: Currency
  language: Language
  notes: string
  paymentMethod: PaymentMethod
  useSplitPayment: boolean
  mergedIn: string | null
  originalDocument: string | null
  itemsList: FICDocumentItem[]
  paymentsList: FICCompleteTranche[]
  extraData: {
    showSofortButton: boolean | null
    multifattureSent: string | null
    accountantCausalCode: string | null
    tsCommunication: boolean
    tsTipoSpesa: string
    tsFlagTipoSpesa: boolean | null
    tsPagamentoTracciato: boolean
    tsOpposizione: boolean | null
    tsStatus: string | null
    tsFileId: number | null
    tsSentDate: string | null
    tsFullAmount: boolean
    importedBy: string | null
  }
  rivalsa: number
  rivalsaTaxable: number
  cassa: number
  cassa2: number
  globalCassaTaxable: number
  cassaTaxable: number
  cassa2Taxable: number
  withholdingTax: number
  withholdingTaxTaxable: number
  otherWithholdingTax: number
  otherWithholdingTaxTaxable: number
  seenDate: string | null
  nextDueDate: string | null
  template: {
    id: number
    name: string
  }
  showTspayButton: boolean
  url: string
  locked: boolean
  hasTsPayPendingPayment: boolean
  isFirstEInvoice: boolean
}

export type PaymentMethod = {
  defaultPaymentAccount: { id: number; name: string } | null
  id: number
  isDefault: boolean
  name: string
  details?: {
    title: string
    description: string
  }[]
}
