import localStorageSlice from './localStorageSlice'
export type { default as LocalStorageStore } from './localStorageStoreTypes'
export type {
  AccountantCall,
  SidebarCounters,
  BankReconciliationWaitingActivation,
  MaintenanceStore,
  SinglePendingExpenseRegistrationType,
  lastAccessTokenActionType,
  localStorageKeys,
  localStorageValues,
  SidebarCounterKey,
} from './localStorageStoreTypes'
export { SinglePendingExpenseRegistrationStatusType } from './localStorageStoreTypes'
export * from './localStorageSelectors'
export { readFromFICConfig, LOCALSTORAGE_FIC_KEY } from './localStorageUtils'
export { default as useSidebarCounter } from './useSidebarCounter'

export const {
  decrementImportSuccessCount,
  resetLocalStorageItem,
  setAccountantCall,
  setAccountantCallBanner,
  setHasAutomaticRenewal,
  setLocalStorage,
  setReduxStorage,
} = localStorageSlice.actions

export const localStorage = localStorageSlice.reducer
