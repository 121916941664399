import React from 'react'
import styled from 'styled-components'

import useCountdown from '../../../common/hooks/useCountdown'

const SubText = styled.div`
  display: inline;
  color: ${({ theme }) => theme.palette.grey['500']};
`

type ErrorPageCounterProps = {
  countDownTime: number
  reloadPage: () => void
}

export const ErrorPageCounter = (props: ErrorPageCounterProps): JSX.Element => {
  const { countDownTime, reloadPage } = props
  const timeLeft = useCountdown(countDownTime, reloadPage)

  return <SubText>Nuovo tentativo tra {timeLeft} secondi. </SubText>
}
