import { BECallStatus } from '../../../common/types'
import {
  APIStatus,
  MAX_DEFAULT_PAYMENT_AMOUNT,
  MIN_DEFAULT_PAYMENT_AMOUNT,
  OnboardingStatus,
} from '../../../components/pages/tspay/TSPayConstants'
import TSPayStore, { TSPayAnonStore } from './tsPayTypes'

export const tsPayAnonInitialState: TSPayAnonStore = {
  active: false,
  expired: false,
  activationDate: undefined,
  configurations: undefined,
  hasConfirmed: false,
}

const tsPayInitialState: TSPayStore = {
  active: false,
  apiKeyActivationUrl: null,
  apiKeyStatus: APIStatus.NOT_ACTIVE,
  authenticationStartedAt: null,
  bankReconciliation: false,
  companyId: 0,
  deactivatedAt: null,
  digitalPos: false,
  fetchStatus: BECallStatus.IDLE,
  id: 0,
  incomingPayments: false,
  maxChargeAmount: MAX_DEFAULT_PAYMENT_AMOUNT,
  merchantRef: null,
  minChargeAmount: MIN_DEFAULT_PAYMENT_AMOUNT,
  onboardingError: null,
  onboardingStartedAt: null,
  onboardingStatus: OnboardingStatus.NOT_STARTED,
  outgoingPayments: false,
  sessionUrl: '',
  tsPayAnon: tsPayAnonInitialState,
}

export default tsPayInitialState
