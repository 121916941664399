import { Draft, PayloadAction } from '@reduxjs/toolkit'

import LocalStorageStore from '../localStorageStoreTypes'

export const decrementImportSuccessCount = (
  state: Draft<LocalStorageStore>,
  action: PayloadAction<{ decrement: number }>
): LocalStorageStore => {
  const { decrement } = action.payload
  const updatedSuccessCount = state.autoImportData.successCount - decrement

  return {
    ...state,
    autoImportData: {
      ...state.autoImportData,
      successCount: updatedSuccessCount,
    },
  }
}
