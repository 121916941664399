import IBAN from 'iban'

import { ValidationResult, ValidatorParams } from './types'
import { Validation } from './validatorResultMaker'

const SIA_REGEX = /^[A-Z0-9$]{5}$/g

/**
 * IBAN validator
 * @param {string} iban - The IBAN to validate
 *
 * @returns {ValidationResult}
 */
export const validateIBAN = (
  iban: string,
  validatorParams: ValidatorParams = {}
): ValidationResult => {
  // Clear spaces due to formatting
  return validatorParams.allowEmpty && iban === ''
    ? Validation.success()
    : IBAN.isValid(iban.replace(/\s/g, ''))
    ? Validation.success()
    : Validation.error('IBAN non valido')
}

/**
 * Bank SIA code validator
 * @param {string} sia - The SIA code to validate
 *
 * @returns {ValidationResult}
 */
export const validateSIA = (sia: string): ValidationResult =>
  SIA_REGEX.test(sia)
    ? Validation.success()
    : Validation.error('Codice SIA non valido')
