import { BECallStatus } from '../../../common/types'
import PreferencesStore from './preferencesStoreTypes'

const preferencesInitialState: PreferencesStore = {
  accountantDashboardTourConf: { showAt: null, dismissCount: 0 },
  automatedSKUScriptCompleted: false,
  closedSubaccountsFeatureInfoModal: false,
  defaultWarehouseStock: 1,
  expensesAirFullAutoRegistration: false,
  expensesAirFullAutoRegistrationFirstTime: false,
  expensesAirSeenFirstTime: undefined,
  expensesAirShowPreviewTooltipCount: 0,
  expensesAirTableSettings: {
    dimensionRow: 42,
    notVisibleColumns: [''],
    orderColumns: [''],
  },
  expensesListTableSettings: {
    orderColumns: [''],
    dimensionRow: 42,
    notVisibleColumns: [''],
  },
  fetchStatus: BECallStatus.IDLE,
  forceShowQuartersPage: false,
  hiddenModules: [],
  hideBannerDuplicatedProductCode: false,
  hideCategoryAssociationPageTour: false,
  hideReportGenerateModal: false,
  lastExpensesAirSync: '',
  sendAutomaticRenewalEmailReminder: false,
  showBankReconciliationModal: true,
  showBankStatementRoundingModal: true,
  showCallDropdownTour: false,
  showCompanyPickerHotkey: true,
  showCompanyPickerTooltip: true,
  showDuplicatesNamesAccountant: true,
  showExpensesAirBanner: {
    value: null,
    snoozedTimes: 0,
  },
  showExpensesAirBulkAutomaticConfirmationModal: true,
  showExpensesAirBulkAutomaticRegistration: false,
  showFailedCheckCreditCardBanner: false,
  showFirstEmptyStateExpensesAir: true,
  showNewArrivimerce: false,
  showNewExpensesAirTour: false,
  showNewExpensesCreation: undefined,
  showNewExpensesFirstAttachmentsTooltip: false,
  showNewExpensesFirstDocumentUploadTooltip: false,
  showNewExpensesFirstMiningTooltip: false,
  showNewExpensesFirstMultipleUploadTooltip: false,
  showNewExpensesFirstSupplierSavedTooltip: false,
  showNewExpensesTour: false,
  showNewTagForWebinarMenu: '',
  showNewWarehouseAdoptionV1: false,
  showNewWarehouseAdoptionV2: false,
  showOtherPaymentsButton: false,
  showSidebarTour: false,
  showTd29: false,
  showUnlockArticleModal: true,
  sidebarBookmarkKeys: [],
  skipExpenseImportHelper: false,
  tsPayAnonEnabled: false,
}

export default preferencesInitialState
