import { Draft, PayloadAction } from '@reduxjs/toolkit'

import TSPayStore, { TSPayAnonStore } from '../tsPayTypes'

export const setTSPayAnonActive = (
  state: Draft<TSPayStore>,
  action: PayloadAction<TSPayAnonStore>
): void => {
  state.tsPayAnon.active = action.payload.active
}

export const setTSPayAnon = (
  state: Draft<TSPayStore>,
  action: PayloadAction<TSPayAnonStore>
): void => {
  state.tsPayAnon = action.payload
}
