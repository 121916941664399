import { createAction } from '@reduxjs/toolkit'

import { normalizeAuth } from '../../features/auth/authNormalizr'
import CompanyDetailsInitialState from '../../features/auth/companyDetails/companyDetailsInitialState'
import { CompanyDetailsStore } from '../../features/auth/companyDetails/companyDetailsStoreTypes'
import { PersonStore } from '../../features/auth/person/personStoreTypes'
import AuthStore from '../../features/auth/types'
import ConfigurationsStore from '../../features/configurations/configurationsStoreTypes'
import { GaTagsStore } from '../../features/gaTags/gaTagsStoreTypes'
import { LocalStorageStore, SidebarCounters } from '../../features/localStorage'
import PreferencesStore from '../../features/preferences/preferencesStoreTypes'
import SupportStore from '../../features/support/supportStoreTypes'
import { TSPayStore } from '../../features/tsPay'
import tsPayInitialState from '../../features/tsPay/tsPayInitialState'

interface ApiAuthObject {
  person: PersonStore
  companies: ApiCompanyType[]
  companyDetails?: CompanyDetailsStore & { tsPay: TSPayStore }
  connectionId: number
  currentSession: string
}

export interface ApiCompanyType {
  name: string
  id: number
  type: string
  accessToken: string
  connectionId: number
  controlledCompanies?: ApiCompanyType[]
}

export interface GlobalParamsApiResponse {
  data: {
    reduxData: {
      accessToken: string
      auth: ApiAuthObject
      configurations: ConfigurationsStore
      preferences: PreferencesStore
      support: SupportStore
      suid?: number
      uid: number
      localStorage: Partial<LocalStorageStore>
    }
    redirectUri: string | null
    sidebarCounters: SidebarCounters
    autoImportData: {
      errors: number[]
      processing: boolean
      successCount: number
    }
  }
  extra: {
    gaTags: GaTagsStore
  }
}

export interface GlobalParamsPayload {
  auth: AuthStore
  configurations: ConfigurationsStore
  preferences: PreferencesStore
  support: SupportStore
  tsPay: TSPayStore
  localStorage: Partial<LocalStorageStore>
}

interface DispatchedGlobalParamsObject {
  payload: GlobalParamsPayload
}

export const setFromApi = createAction(
  'setFromApi',
  (
    response: GlobalParamsApiResponse['data']['reduxData']
  ): DispatchedGlobalParamsObject => {
    const { auth, configurations, localStorage, preferences, support } =
      response
    const { companies, companyDetails, connectionId, currentSession, person } =
      auth

    const { tsPay, ...otherCompanyDetails } = companyDetails || {}

    const normalizedAuth = normalizeAuth(companies)
    person.firstName = person.firstName || ''
    person.lastName = person.lastName || ''
    person.passwordManagement.manualChange = false

    return {
      payload: {
        auth: {
          person,
          companies: normalizedAuth.companies,
          connections: {
            list: normalizedAuth.connections,
            current: connectionId,
          },
          companyDetails: {
            ...CompanyDetailsInitialState,
            ...otherCompanyDetails,
          },
          currentSession,
        },
        configurations,
        preferences,
        support,
        tsPay: { ...tsPayInitialState, ...tsPay },
        localStorage,
      },
    }
  }
)
