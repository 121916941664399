import styled, { css } from 'styled-components'

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`

export const Title = styled.div`
  margin-bottom: 24px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
`

export const WrapperSecondText = styled.div<{ small: boolean }>`
  margin-top: ${props => (props.small ? '16px' : '24px')};
`

export const StyledLink = styled.a`
  outline: none !important;
  color: ${({ theme }) => theme.palette.blue[500]};
  cursor: pointer;
`

export const Wrapper = styled.div<{ small: boolean }>`
  ${props =>
    props.small &&
    css`
      font-size: 12px;
    `}
`
