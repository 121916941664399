import IBAN from 'iban'

import FICDateTime from '../../../../common/dateTime'
import { validateTaxCode } from '../../../../common/utils/validators'
import { BANNED_COMPANY_NAMES } from '../../../../common/utils/validators/const'
import FICNumbers from './FICNumbers'
import FICUtils from './FICUtils'

export default class FICValidator {}

FICValidator.piva = (piva, paese, required = false) => {
  if (!piva && required) {
    return { valid: false, message: 'Partita IVA richiesta.' }
  }
  if (paese !== 'Italia' || !piva) return { valid: true }
  if (FICUtils.controllaPIVA(piva) === '') {
    return { valid: true }
  }
  return { valid: false, message: 'Partita IVA non valida.' }
}

FICValidator.cf = (cf, paese, required = false) => {
  if (!cf && required) {
    return { valid: false, message: 'Codice fiscale richiesto.' }
  }
  if (paese !== 'Italia' || !cf) return { valid: true }

  const taxCodeCheck = validateTaxCode(cf, {
    allowEmpty: true,
  })

  if (taxCodeCheck.valid || FICUtils.controllaPIVA(cf) === '') {
    return { valid: true }
  }
  return { valid: false, message: 'Codice fiscale non valido.' }
}

FICValidator.personCf = cf => {
  if (!cf) {
    return { valid: false, message: 'Codice fiscale richiesto.' }
  }
  const taxCodeCheck = validateTaxCode(cf, {
    allowEmpty: true,
  })
  if (taxCodeCheck.valid) {
    return { valid: true }
  }
  return {
    valid: false,
    message: 'É richiesto un codice fiscale italiano valido.',
  }
}

FICValidator.required = (text, validateCondition = true) => {
  if (!validateCondition) {
    return { valid: true }
  }
  const toEval = text === undefined || text === null ? '' : '' + text
  if (toEval && toEval.length > 0) {
    return { valid: true }
  }
  return { valid: false, message: 'Questo campo non può essere vuoto.' }
}

FICValidator.minLength = (text, minLength) => {
  const toEval = '' + text
  if (toEval && toEval.length >= minLength) {
    return { valid: true }
  }
  return { valid: false, message: 'Il valore inserito è troppo corto.' }
}

FICValidator.iban = (iban, allowEmpty) => {
  const valid = (!iban && allowEmpty) || (iban && IBAN.isValid(iban))
  return {
    valid: valid,
    message: valid
      ? ''
      : allowEmpty
      ? 'IBAN errato.'
      : 'IBAN errato o mancante.',
  }
}

FICValidator.sia = (sia, allowEmpty) => {
  const valid =
    (!sia && allowEmpty) ||
    (sia && sia.length == 5 && sia.match(/^[A-Z0-9$]{5}$/g))
  return {
    valid: valid,
    message: valid
      ? ''
      : allowEmpty
      ? 'Codice SIA errato.'
      : 'Codice SIA errato o mancante.',
  }
}

FICValidator.progressiveNumber = (
  number,
  numerations,
  year,
  numeration,
  needsProgressiveCheck
) => {
  if (numerations && !numerations[year]) {
    return { valid: true }
  }

  const nextDocumentNumber =
    numerations instanceof Array ? 1 : numerations[year][numeration]
  const isInvalid =
    number && needsProgressiveCheck && nextDocumentNumber < number
  return isInvalid
    ? {
        valid: false,
        message:
          'Il numero inserito non è progressivo. Continuando potresti generare un buco nella numerazione.',
        warning: true,
      }
    : { valid: true }
}

FICValidator.numeration = numeration => {
  if (
    numeration &&
    numeration.charAt(0) >= '0' &&
    numeration.charAt(0) <= '9'
  ) {
    return { valid: false, message: 'La numerazione non è valida.' }
  }
  return { valid: true }
}

FICValidator.email = (email, required = false) => {
  if (!email && required) {
    return { valid: false, message: "L'indirizzo e-mail è obbligatorio." }
  }

  if (!email || /^\w+(?:[.+-]\w*)*@\w+(?:[.-]\w+)*(?:\.\w{2,20})+$/.test(email))
    return { valid: true }
  return {
    valid: false,
    message: "L'indirizzo e-mail potrebbe non essere corretto.",
  }
}

FICValidator.multiEmail = (emailString, required = false) => {
  let emailArray = []

  if (emailString) {
    emailArray.push(emailString)

    if (emailString.includes(',')) {
      emailArray = emailString.split(',')
    } else if (emailString.includes(';')) {
      emailArray = emailString.split(';')
    }
  }

  if (!emailArray.length > 0 && required) {
    return { valid: false, message: 'Nessun indirizzo e-mail inserito' }
  }

  if (emailString) {
    if (
      !emailArray.every(email => {
        return FICValidator.email(email.trim(), true).valid
      })
    ) {
      return {
        valid: false,
        message: 'Uno o più indirizzi e-mail inseriti non sono corretti.',
      }
    }
  }
  return { valid: true }
}

FICValidator.eInvoiceCode = (code, pa) => {
  const regex =
    pa === null ? /^[A-Z0-9]{6,7}$/ : pa ? /^[A-Z0-9]{6}$/ : /^[A-Z0-9]{7}$/
  if (!code || regex.test(code)) return { valid: true }
  return {
    valid: false,
    message:
      'Il codice ' + (pa ? 'PA' : 'destinatario') + ' non sembra corretto.',
  }
}

FICValidator.engDate = date => {
  if (!date || FICDateTime.isValid(date)) return { valid: true }
  return { valid: false, message: 'La data non è nel formato corretto.' }
}

FICValidator.between = (value, min, max) => {
  const clearedValue = FICNumbers.parse(value, true)
  if (!isNaN(clearedValue) && clearedValue >= min && clearedValue <= max)
    return { valid: true }
  return {
    valid: false,
    message: 'Il valore deve essere compreso tra ' + min + ' e ' + max,
  }
}

FICValidator.equal = (a, b) => {
  if (a === b) {
    return { valid: true }
  }
  return { valid: false, message: 'I valori non corrispondono.' }
}

FICValidator.postalCode = (code, country = 'Italia', required = false) => {
  if (!code && required) {
    return { valid: false, message: 'Il CAP è obbligatorio.' }
  }
  if (country !== 'Italia' || code === null || code.length === 0) {
    return { valid: true }
  }
  if (code && /^[0-9]{5}$/.test(code)) {
    return { valid: true }
  }
  return { valid: false, message: 'Il CAP non è valido.' }
}

FICValidator.province = (province, country = 'Italia', required = false) => {
  if (!province && required) {
    return { valid: false, message: 'La provincia è obbligatoria.' }
  }
  if (country !== 'Italia' || province === null || province.length === 0) {
    return { valid: true }
  }
  if (province && /^[A-Za-z]{2}$/.test(province)) {
    return { valid: true }
  }
  return { valid: false, message: 'Va indicata la sigla della provincia.' }
}

FICValidator.pecDomain = (email, required = false) => {
  const domains = [
    'hyperpec.it',
    'postacert.it',
    'registerpec.it',
    'dadapec.com',
    'pec-mail.it',
    'pec.it',
    'legalmail.it',
    'hyperpec.it',
    'postacert.it',
    'registerpec.it',
    'dadapec.com',
    'pec-mail.it',
    'arubapec.it',
    'lamiapec.it',
    'pecimprese.it',
    'pec.libero.it',
    'gigapec.it',
    'pec.net',
    'omceo.it',
    'ingpec.eu',
    'psypec.it',
    'pec-legal.it',
    'consulentidellavoropec.it',
    'mypec.eu',
    'pec.cgn.it',
    'pecditta.com',
    'pecdittaindividuale.it',
    'pecavvocati.it',
    'casellapec.com',
    'pec.cloud',
    'open.legalmail.it',
  ]
  const checkMail = FICValidator.email(email, required)
  if (!checkMail.valid) {
    return checkMail
  }
  if (domains.includes(email.split('@')[1])) {
    return {
      valid: false,
      message: "L'utilizzo di un'e-mail PEC è sconsigliato.",
    }
  }
  return { valid: true }
}

FICValidator.disabledVatId = vat => {
  if (vat.is_disabled) {
    return {
      valid: false,
      message:
        'Aliquota IVA non valida a seguito del nuovo tracciato per la fattura elettronica.',
    }
  }
  return { valid: true }
}

FICValidator.natureVatId = vat => {
  if (+vat.value === 0 && !vat.ei_type && !vat.is_disabled) {
    return {
      valid: false,
      message: 'La Natura è obbligatoria per le aliquote al 0%.',
    }
  }
  if (
    (!vat.ei_type || ['2', '3', '6'].includes(vat.ei_type)) &&
    vat.is_disabled
  ) {
    return {
      valid: false,
      message:
        'Natura Aliquota IVA non valida a seguito del nuovo tracciato per la fattura elettronica.',
    }
  }
  return { valid: true }
}

FICValidator.maxTaxableAmountWarning = (value, max, withRivalsa = false) => {
  const message =
    'Imponibile maggiore delle competenze' +
    (withRivalsa ? ' (con rivalsa).' : '.')
  if (value > max) {
    return { valid: false, message: message, isWarning: true }
  }
  return { valid: true }
}

FICValidator.ragioneSociale = value => {
  const trimmedValue = value?.trim().toLowerCase()
  if (trimmedValue && (trimmedValue.length < 3 || trimmedValue.length > 80)) {
    return {
      valid: false,
      message:
        'La ragione sociale deve avere lunghezza compresa tra 3 e 80 caratteri',
    }
  }
  if (BANNED_COMPANY_NAMES.includes(trimmedValue)) {
    return {
      valid: false,
      message: `La ragione sociale non può essere ${trimmedValue}`,
    }
  }
  return { valid: true }
}
