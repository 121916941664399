import { BANNED_COMPANY_NAMES } from './const'
import { ValidationResult, ValidatorParams } from './types'
import { Validation } from './validatorResultMaker'

/**
 * Vat number validator
 * @param {string} companyName - The company name to validate
 * @param {ValidatorParams} [params] - The validation parameters
 * @return {ValidationResult}
 * */
export const validateCompanyName = (
  companyName: string,
  params?: ValidatorParams
): ValidationResult => {
  const trimmedValue = companyName?.trim().toLowerCase()

  if (companyName === '') {
    if (params?.allowEmpty) return Validation.success()

    return Validation.error('La ragione sociale è obbligatoria.')
  }

  if (trimmedValue && (trimmedValue.length < 3 || trimmedValue.length > 80)) {
    return Validation.error(
      'La ragione sociale deve avere lunghezza compresa tra 3 e 80 caratteri.'
    )
  }

  if (BANNED_COMPANY_NAMES.includes(trimmedValue)) {
    return Validation.error(`La ragione sociale non può essere ${trimmedValue}`)
  }

  return Validation.success()
}
