import { Toast, ToastProps } from '@fattureincloud/fic-design-system'
import { ReactNode } from 'react'
import { NavigateFunction } from 'react-router-dom'

import { RecursivePartial } from './common/types'
import { openCustomerly } from './common/utils/appUtils'
import { addBookmarkIdentifier } from './common/utils/bookmarksUtils'
import { logout } from './common/utils/logout'
import { PresentationModalService } from './components/layout/components/basePage/components/modals/components/servicePresentationModal/types'
import { CustomerlySettings } from './plugins/customerly/customerlyTypes'
import { HeadwayTypes } from './plugins/headway/headwayTypes'
import store from './redux'
import { getCompany } from './redux/features/auth/authSelectors/companySelectors'
import {
  incrementUsageLimit,
  setAccountantsCount,
  setCompanyType,
  setForfettariRevenue,
  setInvoiceTradingStatus,
} from './redux/features/auth/companyDetails'
import {
  decrementImportSuccessCount,
  getLocalStorage,
  LocalStorageStore,
  readFromFICConfig,
  setLocalStorage,
} from './redux/features/localStorage'
import {
  closeServicePresentationModal,
  openServicePresentationModal,
  toggleModal,
} from './redux/features/modals/modalsSlice'
import { BooleanModals } from './redux/features/modals/modalsStoreTypes'
import { getBookmarkKeys } from './redux/features/preferences'
import {
  DocumentToTransform,
  setDocumentToTransform,
} from './redux/features/swapStorage'

interface StripeCreateSourceType {
  type: string
  currency: string
  sepa_debit: {
    iban: string
  }
  owner: {
    name: string
  }
}

interface OneSignalFakeTypes {
  isPushNotificationsSupported: () => boolean
  isPushNotificationsEnabled: () => Promise<boolean>
  on: (arg0: string, arg1: () => void) => void
  push: (cb: () => void) => void
  deleteTag: (text: string) => void
}

interface StripeFakeTypes {
  createSource?: (config: StripeCreateSourceType) => any
}

declare global {
  interface Window {
    setCurrentModule: (moduleName: string) => void
    lastRequest: JQuery.jqXHR
    navigate: NavigateFunction
    Headway: HeadwayTypes
    OneSignal: OneSignalFakeTypes
    paypal: any
    Stripe: (key: string, config: { betas: string[] }) => StripeFakeTypes
    stripe: StripeFakeTypes
    reactGlobalParams: any
    customerly?: {
      initialized: boolean
      event: (name: string) => void
      attribute: (name: string, value: string) => void
      update: (settings: RecursivePartial<CustomerlySettings>) => void
      load: (settings: RecursivePartial<CustomerlySettings>) => void
      show: () => void
      hide: () => void
      open: () => void
      close: () => void
      logout: () => void
    }
    openCustomerly: () => void
    openTutorialPage: (id: number, type: string) => void
    renderCalendar: () => void
    setLocalStorage: (obj: LocalStorageStore) => void
    toggleModal: (modalName: BooleanModals) => void
    logout: () => void
    changeInvoicesFamily: (hash: string) => void
    reloadInvoices: any
    reloadGenericOldPage: any
    afterOldPageLoad?: () => void
    beforeOldPageUnload?: () => void
    hj: (method: string, ...params: any) => void
    // Rimuovere quando faremo nuova funzione
    debugging_client: (
      name: string,
      content?: string | Record<string, any> | Record<string, any>[]
    ) => void
    syncReduxFiscalYear: () => void
    setReduxFiscalYear: (year: number) => void
    openToast: (title: ReactNode, options: ToastProps) => string | undefined
    incrementAccountantsCount: (value: number) => void
    incrementSupplierLimitsCount: (increment: number) => void
    // usate per expenses -> da rimuovere
    monthA: number
    monthB: number
    // edit tranches
    updateForfettariRevenue: (amount: number) => void
    // modale export all importa/esporta
    exportall: () => void
    setCompanyType: (companyType: string) => void
    setDocumentToTransform: (doc: DocumentToTransform | undefined) => void
    openServicePresentationModal: (s: PresentationModalService) => void
    closeServicePresentationModal: () => void
    clickOnlyNdC: () => void
    clickAllExpenses: () => void
    decrementImportSuccessCount: () => void
    setInvoiceTradingStatus: (status: string) => void
    getStatusCompanyData: () => string
  }
}

window.stripe = window.stripe || ([] as StripeFakeTypes)
window.OneSignal = window.OneSignal || ([] as unknown as OneSignalFakeTypes)
window.openCustomerly = openCustomerly
window.setLocalStorage = (localStorageObject: Partial<LocalStorageStore>) =>
  store.dispatch(setLocalStorage(localStorageObject))
window.toggleModal = modalName =>
  store.dispatch(toggleModal({ name: modalName }))
window.logout = logout
window.syncReduxFiscalYear = (): void => {
  const currentFiscalYear = +readFromFICConfig('fiscalYear')
  const reduxFiscalYear = getLocalStorage(store.getState()).fiscalYear
  if (currentFiscalYear !== reduxFiscalYear)
    store.dispatch(setLocalStorage({ fiscalYear: currentFiscalYear }))
}
window.setReduxFiscalYear = (year: number) => {
  store.dispatch(setLocalStorage({ fiscalYear: year }))
}
window.setCurrentModule = (moduleName): void => {
  const bookmarkKeys = getBookmarkKeys(store.getState())

  const isBookmark = bookmarkKeys.includes(moduleName)

  store.dispatch(
    setLocalStorage({
      lastSelectedKey: isBookmark
        ? addBookmarkIdentifier(moduleName)
        : moduleName,
    })
  )
}
window.incrementAccountantsCount = (value: number) => {
  const { accountantsCount } = getCompany(store.getState())

  store.dispatch(
    setAccountantsCount({ accountantsCount: accountantsCount + value })
  )
}
window.incrementSupplierLimitsCount = (value: number) => {
  store.dispatch(incrementUsageLimit({ key: 'suppliers', value }))
}
window.openToast = (title, options): string | undefined =>
  Toast.show(title, options)
window.updateForfettariRevenue = (forfettariRevenue: number) =>
  store.dispatch(setForfettariRevenue({ forfettariRevenue }))

window.setCompanyType = (companyType: string) => {
  store.dispatch(setCompanyType({ companyType }))
}
window.setDocumentToTransform = doc =>
  store.dispatch(setDocumentToTransform(doc))

window.openServicePresentationModal = (service: PresentationModalService) =>
  store.dispatch(openServicePresentationModal({ service }))

window.closeServicePresentationModal = () =>
  store.dispatch(closeServicePresentationModal())

window.setExpensesListSelectedMonths = () => {
  const state = store.getState()
  const { expensesListSearchText, expensesListSelectedMonths } =
    getLocalStorage(state)

  window.loadOldData = false
  window.loadOldInputData = false
  window.searchField = expensesListSearchText
  let firstMonth = 0
  let lastMonth = 13

  if (expensesListSelectedMonths.length > 0) {
    firstMonth = expensesListSelectedMonths[0]
    lastMonth =
      expensesListSelectedMonths[expensesListSelectedMonths.length - 1]
  }

  if (window.monthA === firstMonth && window.monthB === lastMonth) {
    return
  }

  window.monthA = firstMonth
  window.monthB = lastMonth
}

window.setInvoiceTradingStatus = (status: string) => {
  store.dispatch(setInvoiceTradingStatus({ status }))
}

window.decrementImportSuccessCount = () => {
  store.dispatch(
    decrementImportSuccessCount({
      decrement: 1,
    })
  )
}

window.getStatusCompanyData = () => {
  return getCompany(store.getState()).companyTsData.status
}
