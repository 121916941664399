import { useEffect, useRef } from 'react'
import { NavigationType } from 'react-router'
import { useLocation, useNavigationType } from 'react-router-dom'

const usePageChangeEffect = (callback: () => void): void => {
  const location = useLocation()
  const action = useNavigationType()

  const prevRef = useRef<{ pathname: string; action: NavigationType | null }>({
    pathname: location.pathname,
    action: action,
  })

  useEffect(() => {
    // Run the callback only when the pathname or action changes
    if (
      prevRef.current.pathname !== location.pathname ||
      prevRef.current.action !== action
    ) {
      prevRef.current = { pathname: location.pathname, action }
      callback()
    }
  }, [location, action, callback])
}

export default usePageChangeEffect
