import { Draft, PayloadAction } from '@reduxjs/toolkit'

import {
  CompanyDetailsStore,
  CopilotAttributesType,
} from '../companyDetailsStoreTypes'

interface SetCopilotAttributesPayload {
  value: CopilotAttributesType
}

export const setCopilotAttributes = (
  state: Draft<CompanyDetailsStore>,
  action: PayloadAction<SetCopilotAttributesPayload>
): void => {
  const { value } = action.payload
  state.copilot = value
}
