import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

import FICColors from '../../styles/FICColors'

function FICRadioImpl(props) {
  return (
    <div
      className={props.className}
      onClick={() => (!props.isDisabled ? props.onClick() : undefined)}
    >
      {props.renderOption ? props.renderOption(props) : renderOption(props)}
    </div>
  )
}

function renderOption(props) {
  return (
    <Option>
      <Radio isChecked={props.isChecked} isDisabled={props.isDisabled} />
      <Label isDisabled={props.isDisabled}>{props.label}</Label>
    </Option>
  )
}

const FICRadio = styled(FICRadioImpl)`
  & + & {
    margin-top: ${props => props.optionsSpacing}px;
  }
  ${props =>
    props.inline &&
    css`
      display: inline-block;
      vertical-align: top;
      &:not(:first-child) {
        margin-left: 25px;
      }
    `}
`

const Option = styled.div`
  margin-bottom: 4px;
  cursor: pointer;
  display: inline-block;
`

const Label = styled.span`
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  padding-left: 8px;
  vertical-align: top;
  ${props =>
    props.isDisabled &&
    css`
      color: ${FICColors.inputDisabledText};
      cursor: not-allowed;
    `}
`

const Radio = styled.span`
  display: inline-block;
  background-color: ${FICColors.white};
  border: 1px solid ${FICColors.borderColor};
  border-radius: 8px;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  ${props =>
    props.isChecked &&
    css`
      border: 5px solid ${FICColors.blue};
    `}
  ${props =>
    props.isDisabled &&
    css`
      background-color: ${FICColors.inputDisabled};
      border-color: ${FICColors.inputDisabled};
      cursor: not-allowed;
      ${props =>
        props.isChecked &&
        css`
          background-color: ${FICColors.white};
          border-color: ${FICColors.button.primary.blue.disabled};
        `}
    `}
`

FICRadioImpl.propTypes =
  FICRadio.propTypes =
  renderOption.propTypes =
    {
      isDisabled: PropTypes.bool,
      isChecked: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      inline: PropTypes.bool,
      /* data: PropTypes.object,
  onClick: PropTypes.func,
  optionsSpacing: PropTypes.number */
    }

FICRadioImpl.defaultProps =
  FICRadio.defaultProps =
  renderOption.defaultProps =
    {
      isDisabled: false,
      isChecked: false,
      data: undefined,
      onClick: undefined,
      optionsSpacing: 0,
      label: '',
      inline: false,
    }

export default FICRadio
