import styled from 'styled-components'

import blob from '../../../resources/images/illustrations/blobs/bottomRightCorner_1.svg'
import { FlexColumn, FlexRow, Image } from '../../common/styled'

export const MaintenancePage = styled(FlexRow)`
  height: calc(100vh - 96px);
  padding: 48px;
  background-color: ${({ theme }) => theme.palette.white[100]};
  background-image: url(${blob});
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  background-size: 110% 110%;
`

export const Left = styled(FlexColumn)`
  flex: 1;
  justify-content: center;
  align-self: stretch;
`

export const Right = styled(FlexColumn)`
  flex: 2;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
  position: relative;
`

export const Logo = styled(Image)`
  width: 200px;
  margin: 0 auto 64px;
`

export const MaintenanceImage = styled(Image)`
  height: auto;
  width: 85%;
`
