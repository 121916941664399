export const version = process.env.REACT_APP_VERSION || ''
export const env = process.env.REACT_APP_ENV || ''
export const hostApp = process.env.REACT_APP_HOST_APP || ''
export const hostSecure = process.env.REACT_APP_HOST_SECURE || ''
export const hostApi = process.env.REACT_APP_HOST_API || ''
export const hostFic = process.env.REACT_APP_HOST_FIC || ''
export const hostCompute = process.env.REACT_APP_HOST_COMPUTE || ''
export const hostCopilot = process.env.REACT_APP_HOST_COPILOT || ''
export const ficCdn = process.env.REACT_APP_FIC_CDN || ''
export const ficCdnGeneral = process.env.REACT_APP_FIC_CDN_GENERAL || ''
export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || ''
export const oneSignalAppId = process.env.REACT_APP_ONESIGNAL_APP_ID || ''
export const oneSignalSafariAppId =
  process.env.REACT_APP_ONESIGNAL_SAFARI_APP_ID || ''
export const sentryDns = process.env.REACT_APP_SENTRY_DSN || ''
export const tsPayDashboard = process.env.REACT_APP_TS_PAY || ''
export const tsPayDigitalPos = process.env.REACT_APP_TS_PAY_DIGITAL_POS || ''
export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''
export const ficClientID = process.env.REACT_APP_CLIENT_ID || ''

export const isProduction = env === 'production'
export const isDevelopment = env === 'development'
export const isLocal = env === 'local'

export const reportEngineHost = process.env.REACT_APP_REPORT_ENGINE_HOST || ''

export const priceListsUrl = process.env.REACT_APP_PRICE_LISTS_URL || ''
