import { Icon } from '@fattureincloud/fic-design-system'
import { faAngleDown, faSearch } from '@fortawesome/pro-light-svg-icons'
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import Select, { components, createFilter } from 'react-select-v2'
import styled, { css } from 'styled-components'

import FICColors from '../../../common/styles/FICColors'
import { getComponentsStyles } from './style'

/**
 * https://react-select.com/props
 * Ogni componente interno può essere sovrascritto con la prop Components
 * @param props
 * @returns {*}
 * @constructor
 */
function FICSelectImpl(props) {
  const componentsStyles = getComponentsStyles(props)
  const renderComponents = getRenderComponents(props)

  let defaultValue
  props.options.forEach(option => {
    if (option.value === props.value) {
      defaultValue = option
    }
  })

  return (
    <Select
      className={props.className}
      classNamePrefix={'ReactSelect'}
      placeholder={props.placeholder}
      isClearable={props.isClearable}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      value={defaultValue}
      backspaceRemoves={false}
      deleteRemoves={false}
      autosize={false}
      filterOption={props.filterOption || createFilter(getFilterConfig(props))}
      isOptionDisabled={props.isOptionDisabled}
      isOptionSelected={props.isOptionSelected}
      isSearchable={props.isSearchable}
      menuIsOpen={props.menuIsOpen}
      defaultMenuIsOpen={props.defaultMenuIsOpen}
      autoFocus={props.autoFocus}
      options={props.options}
      onChange={option => {
        props.onChange(option ? option.value : undefined, option || null)
      }}
      noOptionsMessage={() => 'Nessuna corrispondenza trovata'}
      styles={componentsStyles}
      components={renderComponents}
      maxMenuHeight={props.maxMenuHeight}
    />
  )
}

function getRenderComponents(props) {
  const renderComponents = {}
  renderComponents.IndicatorSeparator = () => null
  renderComponents.DropdownIndicator = () => {
    return (
      <span
        style={{
          marginRight: '8px',
          color: props.isDisabled
            ? FICColors.inputDisabledText
            : FICColors.black,
          position: 'relative',
          top: props.size === 'large' ? '5px' : undefined,
          cursor: 'pointer',
        }}
      >
        <Icon icon={faAngleDown} />
      </span>
    )
  }
  renderComponents.clearIndicator = () => {
    return (
      <span
        style={{
          marginRight: '5px',
          color: props.isDisabled
            ? FICColors.inputDisabledText
            : FICColors.black,
          position: 'relative',
          top: props.size === 'large' ? '5px' : undefined,
          cursor: 'pointer',
        }}
      >
        <Icon icon={faTimes} />
      </span>
    )
  }

  renderComponents.Option = props => {
    return (
      <components.Option {...props}>
        {props.children}
        <span
          style={{
            position: 'absolute',
            right: 10,
            marginTop: 5,
            display: 'none',
          }}
        >
          <Icon icon={faCheck} />
        </span>
      </components.Option>
    )
  }

  if (props.isSearchable) {
    renderComponents.ValueContainer = props => {
      return (
        <Fragment>
          <span style={{ margin: '0 10px' }}>
            <Icon icon={faSearch} />
          </span>
          <IsSearchableContainer {...props}>
            {props.children}
          </IsSearchableContainer>
        </Fragment>
      )
    }
  }

  if (props.menuIsOpen) {
    renderComponents.DropdownIndicator = () => null
    renderComponents.Menu = props => {
      return (
        <MenuOpenContainer style={{ color: 'red' }} {...props}>
          {props.children}
        </MenuOpenContainer>
      )
    }
  }

  if (props.renderOption) {
    renderComponents.Option = ({ children, innerProps }) =>
      props.renderOption(children, innerProps)
  }

  return renderComponents
}

function getFilterConfig(props) {
  const ignoreCase = props.ignoreCase || true
  const ignoreAccents = props.ignoreAccents || true
  return {
    ignoreCase,
    ignoreAccents,
  }
}

const FICSelect = styled(FICSelectImpl)`
  width: ${props => props.width || '100%'};
  height: ${props => (props.size === 'large' ? '40px' : '30px')};
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `};
  text-align: left;
  font-size: 14px;
  cursor: pointer;
  background-color: ${FICColors.white};
  border: 1px solid ${FICColors.borderColor};
  border-radius: 4px;
  #react-select-2-input {
    line-height: 30px;
    height: 30px;
    display: inline-block;
    padding: 4px 0px;
    margin-bottom: 0;
    color: #555555;
    vertical-align: middle;
  }
  textarea:hover,
  input:hover,
  textarea:active,
  input:active,
  textarea:focus,
  input:focus,
  button:focus,
  button:active,
  button:hover,
  label:focus,
  .btn:active,
  .btn.active {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
  }
  .ReactSelect__value-container > div {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  & input {
    margin-bottom: 2px;
  }
`

const IsSearchableContainer = styled(components.ValueContainer)`
  width: calc(100% - 70px);
  cursor: ${props => (!props.hasValue ? 'text' : 'auto')};
  padding: 0 !important;
`

const MenuOpenContainer = styled(components.Menu)`
  box-shadow: none !important;
  > div {
    max-height: unset;
  }
`

FICSelectImpl.propTypes = FICSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  valueKey: PropTypes.string,
  emptyLabel: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  maxMenuHeight: PropTypes.number,
  style: PropTypes.object,
  filterOption: PropTypes.func,
  getOptionLabel: PropTypes.string,
  getOptionValue: PropTypes.string,
  isOptionDisabled: PropTypes.bool,
  isOptionSelected: PropTypes.bool,
  isValid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
  defaultMenuIsOpen: PropTypes.bool,
  size: PropTypes.string,
}

FICSelectImpl.defaultProps = FICSelect.defaultProps = {
  options: [],
  value: '',
  valueKey: 'value',
  emptyLabel: '',
  placeholder: '',
  onChange: undefined,
  clearable: false,
  maxMenuHeight: null,
  style: {},
  filterOption: undefined,
  getOptionLabel: '',
  getOptionValue: undefined,
  isOptionDisabled: false,
  isOptionSelected: false,
  isValid: undefined,
  isDisabled: false,
  isSearchable: false,
  menuIsOpen: undefined,
  defaultMenuIsOpen: undefined,
  size: undefined,
}

getRenderComponents.propTypes = {
  isDisabled: PropTypes.bool,
  size: PropTypes.string,
}

export default FICSelect
