import tsPaySlice from './tsPaySlice'
export type { default as TSPayStore } from './tsPayTypes'
export { default as activateOutgoingPayments } from './thunks/activateOutgoingPayments'
export * from './tsPaySelectors'

export const {
  setBankReconciliationActive,
  setChargeAmounts,
  setDigitalPOSActive,
  setIncomingPaymentsActive,
  setOutgoingPaymentsActive,
  setTSPay,
  setTSPayActive,
  setTSPayAnon,
  setTSPayAnonActive,
  setTSPayFetchStatus,
} = tsPaySlice.actions

export const tsPay = tsPaySlice.reducer
