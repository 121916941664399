import { createSelector } from '@reduxjs/toolkit'

import { GlobalStore } from '../../app/rootReducer'
import { Country, ReduxCountry } from './configurationsStoreTypes'

export const getConfiguration = (state: GlobalStore) => state.configurations

export const getCurrenciesConfig = (state: GlobalStore) =>
  getConfiguration(state).currencies

export const getCountriesConfig = (state: GlobalStore): ReduxCountry[] =>
  getConfiguration(state).countries

export const getChatConfig = (state: GlobalStore) =>
  getConfiguration(state).chat

export const getIsChatVisible = (state: GlobalStore) =>
  state.configurations.chat.globalVisible

export const getCurrencies = createSelector(
  [getCurrenciesConfig],
  currencies => {
    return currencies.map(currency => ({
      ...currency,
      label: `${currency.symbol || ''} ${currency.name || ''}`
        .replace(/\s+/g, ' ')
        .trim(),
      value: currency.id,
      customExchangeRate: currency.exchangeRate ?? undefined,
    }))
  }
)

export const getCountries = createSelector(
  [getCountriesConfig],
  (countries: ReduxCountry[]): Country[] =>
    countries.map<Country>(country => ({
      ...country,
      label: country.settingsName,
      value: country.settingsName,
    }))
)

export const getNotBannedCountries = (state: GlobalStore): Country[] =>
  getConfiguration(state)
    .countries.filter(item => !item.banned)
    .map(item => ({
      ...item,
      label: item.settingsName,
      value: item.settingsName,
    }))
