import { Icon } from '@fattureincloud/fic-design-system'
import { faCloud } from '@fortawesome/pro-solid-svg-icons'
import React, { CSSProperties } from 'react'
import styled, { css } from 'styled-components'

type Size = 'small' | 'medium' | 'large'

type Props = {
  className?: string
  fullscreenCentered?: boolean
  size?: Size
  style?: CSSProperties
}

const sizeMap: Record<Size, string> = {
  small: '48px',
  medium: '72px',
  large: '150px',
}

const LoadingCloud = ({
  className,
  fullscreenCentered,
  size = 'large',
  style,
}: Props): JSX.Element => (
  <LoadingIconWrapper
    fullscreenCentered={fullscreenCentered}
    customSize={size}
    style={style}
    className={className}
  >
    <LoadingIcon icon={faCloud} />
  </LoadingIconWrapper>
)

type LoadingIconWrapperProps = {
  customSize: Size
  fullscreenCentered?: boolean
}

const LoadingIconWrapper = styled.div<LoadingIconWrapperProps>`
  font-size: ${({ customSize }) => sizeMap[customSize]};

  ${({ fullscreenCentered }) =>
    fullscreenCentered &&
    css`
      height: 100%;
      width: 100%;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    `}
`

const LoadingIcon = styled(Icon)`
  @keyframes cloud-animation {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.1, 0.9);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  animation: cloud-animation 1s infinite cubic-bezier(0.4, 0.66, 0.43, 0.25);
  transform-origin: 50% 100%;
  color: ${({ theme }) => theme.palette.grey[100]};
`

export default LoadingCloud
